.faq-container-helpers {
    margin:0 0 20px;
    color:#666;
}
.faq-container-helpers__link {
    border:0;
    background:none;
    font-family:$font-tertiary;
    font-weight:bold;
    padding:0;
    display:inline-block;
    cursor:pointer;
    
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.faq-list {
    @include resetList;
    box-shadow:0px 3px 6px -2px #333;
    margin:0 0 30px;
    border-radius: 4px;
}
.faq-list__item {
    border-bottom:1px solid #999;

    &:last-child {
        border:0;
    }
}
.faq-button {
    border:0;
    padding:15px 40px 15px 15px;
    width:100%;
    display:block;
    background:none;
    font-weight:bold;
    @include x-rem(font-size, 20px);
    cursor:pointer;
    color:$color-primary;
    font-family:$font-secondary;
    position: relative;
    text-align: left;

    &:before {
        content:'';
        width:16px;
        height:3px;
        background:currentColor;
        position: absolute;
        top:24px;
        right:13px;
    }
    &:after {
        content:'';
        width:3px;
        height:16px;
        background:currentColor;
        position: absolute;
        top:18px;
        right:19px;
    }

    &.active {
        &:after {
            display:none;
        }
    }
    &:hover,
    &:focus {
        background:#dddddd; 
    }
}
.faq-content {
    padding:0 15px 15px;

    p:last-child {
        margin:0;
    }
}
.faq-content-helpers {
    padding:15px 0 0 0;
}
.faq-content-helpers__link {
    border:0;
    padding:0;
    background:none;
    cursor:pointer;
    color:$color-primary;
    margin:0 10px 0 0;

    &:hover,
    &:focus {
        color:$color-secondary;
    }
}
.faq-content-helpers__link__icon {
    width:24px;
    height:24px;
    fill:currentColor;
}

@media (min-width: $bp-mw) {
    .faq-container-helpers {
        margin:0 0 35px;
    }
    .faq-list {
        margin:0 0 50px;
    }
    .faq-button {
        @include x-rem(font-size, 24px);
        padding:25px 40px;

        &:before {
            top:36px;
        }
        &:after {
            top:29px;
        }
    }
    .faq-content {
        padding:0 40px 30px;
        @include x-rem(font-size, 20px);
    }

}


.faq-rail {
    box-shadow:0px 3px 6px -2px #333;
    margin:0 0 30px;
    background:#f1f1f1;
    padding:25px 20px;
    border-radius: 4px;
}
.faq-rail__heading {
    margin:0;
    border-bottom:3px solid $color-secondary;
    @include x-rem(font-size, 20px);
    padding:0 0 12px;
}
.faq-rail__list {
    @include resetList;
}
.faq-rail__list-item {
    border-bottom:1px solid #999;
}
.faq-rail__button {
    display:flex;
    align-items: center;
    padding:15px 0;
    font-family: $font-tertiary;
    font-weight:bold;
    border:0;
    background:none;
    cursor:pointer;
    text-align: left;

    &:after {
        content:'';
        background-image:url(../images/right-arrow.svg);
        width:8px;
        height:12px;
        margin:0 0 0 7px;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }
    
}
.faq-rail__content {
    padding:0 0 10px;
}

@media (min-width: $bp-mw) {
    .faq-rail {
        margin:0 0 50px;
        padding:40px;
    }
    .faq-rail__heading {
        @include x-rem(font-size, 24px);
    }
}



.faq-grid-list {
    @include resetList;
    margin:0 -10px;
}
.faq-grid-list__item {
    margin:0 0 30px;
    padding:0 10px;
    transition: all linear .3s;
}
.faq-grid-list__button {
    background:$color-primary;
    color:#fff;
    padding:30px;
    border:0;
    width:100%;
    height:100%;
    font-weight:600;
    line-height:1.4;
    @include x-rem(font-size, 18px);
    cursor:pointer;

    &:hover,
    &:focus {
        background:$color-secondary-lightest;
        color:$color-primary;
    }
}
.faq-grid-list__content {
    padding:10px 30px 30px 30px;
    background:$color-secondary-lightest;
    @include x-rem(font-size, 18px);
}

// active states

.faq-grid-list__item.active {

    .faq-grid-list__button {
        height:auto;
        background:$color-secondary-lightest;
        color:$color-primary;
    }
}

@media (min-width: $bp-m) {
    .faq-grid-list {
        display:flex;
        flex-wrap: wrap;
    }
    .faq-grid-list__item {
        width:50%;
    }
}

@media (min-width: $bp-mw) {
    .faq-grid-list__item {
        width:33.33%;
    }
}