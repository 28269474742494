/* ******************************************************
   Main Import File
        - Variables / Utilities / Globals
        - Components (Navigation/Tools/Modules)
        - Vendor Imports (Third party plugins)
        - Overwrite Imports (Print/admin)
   ****************************************************** */


/* ================================================================
   Variables / Utilities / Globals
   ================================================================ */

// Variables
@import "variables";

// SASS Functions and Modules
@import "utilities";

// Normalize
@import "globals/normalize";

// Base Styles
@import "globals/boilerplate";
@import "globals/grid";

// Layout Styles
@import "globals/layout/base";
@import "globals/layout/header";
@import "globals/layout/interior";
@import "globals/layout/footer";

// Global Element Styles
@import "globals/typography";
@import "globals/buttons";
@import "globals/data-expander";
@import "globals/tables";
@import "globals/forms";

// Special
@import "globals/animations";

/* ================================================================
   Component Imports - Comment/uncomment what you don't need/need.
   ================================================================ */

// Component Globals
@import "components/components";

// Navigation
@import "components/navigation/clickMenu";
@import "components/navigation/main-navigation";
@import "components/navigation/rail-navigation";
@import "components/navigation/hamburger";
@import "components/navigation/footerNav";
@import "components/navigation/sectionNav";
@import "components/navigation/breadcrumbs";

// Tools
@import "components/tools/tabs";
@import "components/tools/pagination";
@import "components/tools/pagetools";
@import "components/tools/search";

// Modules
@import "components/modules/banner";
@import "components/modules/biography";
@import "components/modules/expandable";
@import "components/modules/events";
@import "components/modules/news";
@import "components/modules/videos";
@import "components/modules/alerts";
@import "components/modules/cookie-consent";
@import "components/modules/lists";
@import "components/modules/filters";
@import "components/modules/hero";
@import "components/modules/callouts";
@import "components/modules/page-banner";
@import "components/modules/cards";
@import "components/modules/side-by-side";
@import "components/modules/image-slider";
@import "components/modules/quicklinks";
@import "components/modules/faq";
@import "components/modules/articles";
@import "components/modules/compare";
@import "components/modules/locations";
@import "components/modules/board-listing";
@import "components/modules/searching";
@import "components/modules/video-banner";
@import "components/modules/directors";
@import "components/modules/resources";
@import "components/modules/landing";
@import "components/modules/landing-listing";
@import "components/modules/carrier-listing";
@import "components/modules/newsletter-slider";
@import "components/modules/promotions";
@import "components/modules/news-carousel";
@import "components/modules/curator";
@import "components/modules/rating";

/* ================================================================
   Vendor Imports
   ================================================================ */

@import "vendor/a11y-slider";
@import "vendor/modaal";
@import "vendor/froala";
@import "vendor/be-links";

/* ================================================================
   Pages
   ================================================================ */

// Admin styles, resets and fixes
@import "pages/styleguide";
@import "pages/newsletter";
@import "pages/newsletter-detail";
@import "pages/sitemap";


/* ================================================================
   Overwrite Imports
   ================================================================ */

// Print
@import "globals/print";
