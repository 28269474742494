.landing-listing-container {

}
.landing-listing-item {
    padding:30px 15px;
    background:$color-invert;

    &:nth-child(2n) {
        background:$color-gray;
    }
}
.landing-listing-item__inner {
    max-width:1370px;
    margin:0 auto;
}
.landing-listing-item__image {
    height:300px;
    background-size: cover;
    background-position: 50% 50%;
    margin:0 0 15px;
}
.landing-listing-item__content {

}
.landing-listing-item__heading {
    @include x-rem(font-size, 20px);
    font-family: $font-primary;
}
.landing-listing-item__description {
    margin:0 0 25px;
}

@media (min-width: $bp-m) {
    .landing-listing-container {

    }
    .landing-listing-item {
        padding:55px 15px;

        &:nth-child(2n) {
            .landing-listing-item__image {
                order:2;
                margin:0 0 0 7%;
            }
        }
    }
    .landing-listing-item__inner {
        display:flex;
        align-items: center;
    }
    .landing-listing-item__image {
        min-height:340px;
        flex:0 0 35%;
        margin:0 7% 0 0;
    }
    .landing-listing-item__content {
        flex:1 1 auto;
    }
    .landing-listing-item__heading {
        @include x-rem(font-size, 24px);
    }
    .landing-listing-item__description {
        @include x-rem(font-size, 20px);
    }
}
.landing-listing-container--with-videos {
    .landing-listing-item__image {
        height:auto;
        min-height:0;
    }
}