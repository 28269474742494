/* ================================================================
   Hero Slider and Static
   ================================================================ */

.hero-slider-container {
    position: relative;

    .a11y-slider-dots {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        margin: 0;

        li {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        button {
            background: transparent;
            border: 2px solid #fff;
            width: 14px;
            height: 14px;

            &.active {
                background: #ffffff;
                width: 18px;
                height: 18px;
            }
        }
    }
}

.hero-slider {
    @include resetList;
    display: flex;
    align-items: flex-start;
    transition: $trans;
}

.hero-slide {
    width: 100%;
    flex: 1 0 auto;
    background: no-repeat center / cover;
}

.hero-slide__img {
    padding-top: percentage(9/16);
    background: no-repeat center / cover;
}

.hero-slide--video {

    .hero-slide__video {
        position: relative;
    }

    .video-container {
        margin: 0;
        padding-bottom: 36.25%;

        iframe {
            max-width: none;
        }

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 2;
        }
    }

    .video-transcript {
        position: absolute;
        z-index: 3;
        right: 20px;
        bottom: 20px;
        margin: 0;

        a {
            @extend .button;
            padding: 6px 10px;
            @include x-rem(font-size, 14px);
        }
    }

    .hero-slide__container {
        position: relative;
        z-index: 3;
    }
}

.hero-slide__container {
    max-width: $container-size;
    width: 100%;
    margin: 0 auto;

    *::selection {
        background: $color-secondary;
    }
}

.hero-slide__inner {
    background: $color-primary;
    padding: 20px 20px 40px;
    color: $color-invert;
    text-align: center;

    &.color-gold {
        background: $color-gold;

        .hero-slide-info-box__title {
            color: $color-primary;
        }

        .hero-slide-info-box__description {
            color: $color-base;
        }
    }

    &.color-white {
        background: $color-invert;

        .hero-slide-info-box__title {
            color: $color-primary;
        }

        .hero-slide-info-box__description {
            color: $color-base;
        }
    }

    &.color-light-gray {
        background: $color-gray;

        .hero-slide-info-box__title {
            color: $color-primary;
        }

        .hero-slide-info-box__description {
            color: $color-base;
        }
    }

    &.color-gray {
        background: $color-gray2;

        .hero-slide-info-box__title {
            color: $color-invert;
        }

        .hero-slide-info-box__description {
            color: $color-invert;
        }
    }

    &.color-light-blue {
        background: $color-light-blue;

        .hero-slide-info-box__title {
            color: $color-primary;
        }

        .hero-slide-info-box__description {
            color: $color-base;
        }
    }

    &.color-teal {
        background: $color-teal;

        .hero-slide-info-box__title {
            color: $color-primary;
        }

        .hero-slide-info-box__description {
            color: $color-base;
        }
    }
}

.hero-slide-info-box__title {
    @include fluid-type($bp-s, $bp-mw, 30px, 60px);
    font-weight: bold;
    margin: 0 0 10px;
    line-height: 1.1;
    color: $color-invert;
}

.hero-slide-info-box__description {
    @include fluid-type($bp-s, $bp-mw, 16px, 20px);
    font-weight: normal;
    margin: 0 0 15px;
    line-height: 1.6;
}

.hero-slide-info-box__button {
}

.hero-slide-controls {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    z-index: 10;
}

.hero-slider-btn {
    @extend %carousel-btn;
    background: #ffffff;
    box-shadow: 2px ​2px 3px -2px #333;
    border-radius: 50%;
    padding: 10px;
    width: 46px;
    height: 46px;


    &--prev {
        left: 10px;
    }

    &--next {
        right: 10px;
    }
}

@media (max-width: $bp-m-max) {
    .hero-slide {
        background: none !important;
    }
}

@media (min-width: $bp-mw) {
    .hero-slider-container {

        .a11y-slider-dots {
            bottom: 20px;
        }
    }

    .hero-slide {
        position: relative;
        display: flex;
        min-height: 650px;
        align-items: center;
        height: 100px;
    }

    .hero-slide__img {
        display: none;
    }

    .hero-slide--video {
        position: relative;

        .hero-slide__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 750px;
            padding: 0;
        }

        .video-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 0;
        }

        &.LowerLeft {
            .hero-slide__container {
                width: auto;
                position: absolute;
                bottom: 20px;
                left: 20px;
                padding: 0;
            }
        }

        &.Centered {
            .hero-slide__container {
                width: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }

            .hero-slide__inner {
                text-align: center;
            }
        }
    }

    .hero-slide__container {
        padding: 0 $grid-gutter-em;
    }

    .hero-slide__inner {
        position: relative;
        background: transparentize($color: $color-primary, $amount: .35);
        padding: 50px 60px;
        max-width: 685px;
        border-radius: 4px;
        text-align: left;

        &.right {
            float: right;
        }

        &.color-gold {
            background: transparentize($color: $color-gold, $amount: .35);
        }

        &.color-white {
            background: transparentize($color: $color-invert, $amount: .35);
        }

        &.color-light-gray {
            background: transparentize($color: $color-gray, $amount: .35);
        }

        &.color-gray {
            background: transparentize($color: $color-gray2, $amount: .35);
        }

        &.color-light-blue {
            background: transparentize($color: $color-light-blue, $amount: .35);
        }

        &.color-teal {
            background: transparentize($color: $color-teal, $amount: .35);
        }

        &.no-text-underlay {
            background: none;
        }
    }

    .hero-slide-info-box__title {
        margin: 0 0 10px;
        letter-spacing: -1px;
    }

    .hero-slide-info-box__subtitle {
        margin: 0 0 30px;
    }

    .hero-slide-info-box__btn {
        display: inline-block;
    }

    .hero-slide__img {
        position: absolute;
        top: 0;
        right: 0;
        width: 1200px;
        height: 100%;
        z-index: 1;
        background: no-repeat top right / cover;

        &::before {
            content: none;
        }

        &::after {
            background-size: auto;
            background-position: bottom right;
        }
    }

    .hero-slide-controls {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
    }
}

@media (min-width: 1730px) {
    .hero-slider-btn {
        &--prev {
            left: 8%;
        }

        &--next {
            right: 8%;
        }
    }
}

.hero-slider-container--overlap {
    .hero-slide__inner {
        color: $color-invert;
        background: $color-primary;
    }

    .hero-slide__inner.color-white .hero-slide-info-box__description {
        color: $color-invert;
    }

    .hero-slide__inner.color-white h1.hero-slide-info-box__title {
        color: $color-invert;
    }

    @include breakpoint($bp-mw) {
        @include x-rem(margin-bottom, 233px);

        .hero-slide__container {
            align-self: flex-end;
        }

        .hero-slider {
            @include x-rem(margin-bottom, 233px);
        }

        .hero-slide__inner {
            @include x-rem(margin-bottom, -164px);
            @include x-rem(max-width, 789px);
        }
    }
}

// offset hero container specific styles
.hero-slider-container--offset {
    @include breakpoint($bp-mw) {
        overflow: hidden;

        .a11y-slider-dots {
            bottom: 246px;
            left: 200px
        }

        .hero-slider-btn {
            width: 72px;
            height: 72px;
        }
    }
}
