/* ================================================================
Base styles
================================================================ */

.site-container {

}
.section-container {
    padding: 30px 0;
	&.section-container--white {
		background: $body-bg;
	}
	&.section-container--gray {
		background: $bg-location;
	}
	&.section-container--gray-dark {
		background: $bg-location-darker;
	}
	&.section-container--gold {
		background: $color-gold2;
	}
	&.section-container--dark-blue {
		@include lighten-text;
		background: $color-primary;
	}
	&--padding-top {
		padding: 30px 0 0;
	}
	&--padding-bottom {
		padding: 0 0 30px;
	}
	&--padding-small {
		padding: 20px 0;
		&.section-container--padding-top {
			padding: 20px 0 0;
		}
		&.section-container--padding-bottom {
			padding: 0 0 20px;
		}
	}
	&--padding-medium {
		padding: 50px 0;
		&.section-container--padding-top {
			padding: 50px 0 0;
		}
		&.section-container--padding-bottom {
			padding: 0 0 50px;
		}
	}
	&--padding-large {
		padding: 100px 0;
		&.section-container--padding-top {
			padding: 100px 0 0;
		}
		&.section-container--padding-bottom {
			padding: 0 0 100px;
		}
	}
	&--no-padding {
		padding: 0;
	}
}

.back-to-top {
    position: fixed;
    background:$color-primary;
    border:1px solid #fff;
    border-radius: 50%;
    width:40px;
    height:40px;
    right:10px;
    bottom:10px;
    z-index:998;
    display:none;

    &:before {
        content:'';
        position: absolute;
        top:50%;
        left:50%;
        width:12px;
        height:2px;
        background:#fff;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        margin:0 0 0 4px;
    }
    &:after {
        content:'';
        position: absolute;
        top:50%;
        left:50%;
        width:12px;
        height:2px;
        background:#fff;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        margin:0 0 0 -4px;
    }

    &:hover,
    &:focus {
        background:$color-secondary;
    }
}

@media (min-width: $bp-mw) {
    .section-container {
        padding:60px 0;

		&--padding-top {
			padding: 60px 0 0;
		}
		&--padding-bottom {
			padding: 0 0 60px;
		}
		&--padding-small {
			padding: 50px 0;
			&.section-container--padding-top {
				padding: 50px 0 0;
			}
			&.section-container--padding-bottom {
				padding: 0 0 50px;
			}
		}
		&--padding-medium {
			padding: 80px 0;
			&.section-container--padding-top {
				padding: 80px 0 0;
			}
			&.section-container--padding-bottom {
				padding: 0 0 80px;
			}
		}
		&--padding-large {
			padding: 120px 0;
			&.section-container--padding-top {
				padding: 120px 0 0;
			}
			&.section-container--padding-bottom {
				padding: 0 0 120px;
			}
		}
		&--no-padding {
			padding: 0;
		}
    }
}