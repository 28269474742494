/* ================================================================
   Simple Banner
   ================================================================ */

.banner-full-width {
   border-bottom:1px solid #ccc;
}
.banner-full-width__img {
   height:215px;
   background-size:cover;
   background-repeat:0 0;
   border-bottom:3px solid $color-secondary;
}  
.banner-full-width__container {
   max-width:$container-size;
   width:100%;
   margin:0 auto;
}
.banner-full-width__inner {
   background:$color-invert;
   padding:20px 25px 30px;
   text-align:center;

   &.color-blue {
      background:$color-primary;

      .banner-full-width__heading { 
         color:$color-invert;
      }
      .banner-full-width__description { 
         color:$color-invert;
      }
  }
   &.color-gold {
      background:$color-gold;

      .banner-full-width__heading { 
         color:$color-primary;
      }
      .banner-full-width__description { 
         color:$color-base;
      }
  }
  &.color-white {
      background:$color-invert;

      .banner-full-width__heading { 
         color:$color-primary;
      }
      .banner-full-width__description { 
         color:$color-base;
      }
  }
  &.color-light-gray {
      background: $color-gray;

      .banner-full-width__heading { 
         color:$color-primary;
      }
      .banner-full-width__description { 
         color:$color-base;
      }
  }
  &.color-gray {
      background: $color-gray2;

      .banner-full-width__heading { 
         color:$color-invert;
      }
      .banner-full-width__description { 
         color:$color-invert;
      }
   }
  &.color-light-blue {
       background: $color-light-blue;

      .banner-full-width__heading { 
         color:$color-primary;
      }
      .banner-full-width__description { 
         color:$color-base;
      }
  }
  &.color-teal {
      background: $color-teal;

      .banner-full-width__heading { 
         color:$color-primary;
      }
      .banner-full-width__description { 
         color:$color-base;
      }
  }
   &.no-text-underlay {
      background:none;
   }
}

.banner-full-width__heading {
   color:$color-primary;
   @include fluid-type($bp-s, $bp-mw, 30px, 50px);
   margin:0 0 10px;
   line-height:1.1;
}
.banner-full-width__description {
   @include fluid-type($bp-s, $bp-mw, 16px, 20px);
   color:#555;
   line-height:1.6;
   margin:0 0 15px;
}
.banner-full-width__buttons {      
   .button {
      margin:10px 0 0 0;
      min-width:275px;
   }
}

.banner-full-width--left {
   background-position: 100% 0;

   .banner-full-width__img {
      background-position: 100% 0;
   }
}


@media (min-width: $bp-mw) {
   .banner-full-width {
      background-size: cover;
      border:0;
   }
   .banner-full-width__img {
      display:none;
   }
   .banner-full-width__container {
      display:flex;
      align-items: center;
      min-height:640px;
      height:100px;
      padding:0 $grid-gutter-em;
   }

   .banner-full-width__heading {
      position: relative;

      &:after {
         content:'';
         position: absolute;
         top:27px;
         left:100%;
         width:100px;
         height:3px;
         background:$color-secondary;
      }
   }

   .banner-full-width__inner {
      width:50%;
      background:transparentize($color: $color-invert, $amount: .15);
      padding:50px 60px 60px;
      text-align: left;
      border-radius: 4px;

      &.color-blue {
         background:transparentize($color: $color-primary, $amount: .15);

         .banner-full-width__heading { 
            color:$color-invert;
         }
         .banner-full-width__description { 
            color:$color-invert;
         }
     }
      &.color-gold {
         background:transparentize($color: $color-gold, $amount: .15);

         .banner-full-width__heading { 
            color:$color-primary;
         }
         .banner-full-width__description { 
            color:$color-base;
         }
     }
     &.color-white {
         background:transparentize($color: $color-invert, $amount: .15);

         .banner-full-width__heading { 
            color:$color-primary;
         }
         .banner-full-width__description { 
            color:$color-base;
         }
     }
     &.color-light-gray {
         background:transparentize($color: $color-gray, $amount: .15);

         .banner-full-width__heading { 
            color:$color-primary;
         }
         .banner-full-width__description { 
            color:$color-base;
         }
     }
     &.color-gray {
         background:transparentize($color: $color-gray2, $amount: .15);

         .banner-full-width__heading { 
            color:$color-invert;
         }
         .banner-full-width__description { 
            color:$color-invert;
         }
      }
     &.color-light-blue {
         background:transparentize($color: $color-light-blue, $amount: .15);

         .banner-full-width__heading { 
            color:$color-primary;
         }
         .banner-full-width__description { 
            color:$color-base;
         }
     }
     &.color-teal {
         background:transparentize($color: $color-teal, $amount: .15);

         .banner-full-width__heading { 
            color:$color-primary;
         }
         .banner-full-width__description { 
            color:$color-base;
         }
     }
     &.no-text-underlay {
         background:none;
      }
   }
   .banner-full-width__buttons {
      margin:0 -5px;
      display:flex;
      justify-content: center;

      .button {
         margin:0 5px;
         min-width: 0;
      }
   }

   .banner-full-width--right {
      .banner-full-width__container {
         justify-content: flex-end;
      }
      .banner-full-width__heading { 
         &:after {
            left:auto;
            right:calc(100% + 28px);
         }
      }
   }
   .banner-full-width--dark {
      .banner-full-width__inner {
         background:transparentize($color: $color-primary, $amount: .15)
      }
   }
}


.banner-small-container {
   padding:30px 0 15px;
}
.banner-small-block {
   padding:0 15px;
}
.banner-small {
   background-size:cover;
   background-position: 100% 0;
   margin:15px auto;

   &.color-blue {
      .banner-small__img {
         &:after {
            background: linear-gradient(180deg, transparentize($color-primary, 1) 0%, $color-primary 100%);
         }
      }
      .banner-small__inner {
         background:$color-primary;
      }
      .banner-small__heading {
         color:$color-invert;
      }
      .banner-small__description {
         color:$color-invert;
      }
  }
   &.color-gold {
      .banner-small__img {
         &:after {
            background: linear-gradient(180deg, transparentize($color-gold, 1) 0%, $color-gold 100%);
         }
      }
      .banner-small__inner {
         background:$color-gold;
      }
      .banner-small__heading {
         color:$color-primary;
      }
      .banner-small__description {
         color:$color-base;
      }
  }
  &.color-white {
      .banner-small__img {
         &:after {
            background: linear-gradient(180deg, transparentize($color-invert, 1) 0%, $color-invert 100%);
         }
      }
      .banner-small__inner {
         background:$color-invert;
      }
      .banner-small__heading {
         color:$color-primary;
      }
      .banner-small__description {
         color:$color-base;
      }
  }
  &.color-light-gray {
      .banner-small__img {
         &:after {
            background: linear-gradient(180deg, transparentize($color-gray, 1) 0%, $color-gray 100%);
         }
      }
      .banner-small__inner {
         background:$color-gray;
      }
      .banner-small__heading {
         color:$color-primary;
      }
      .banner-small__description {
         color:$color-base;
      }
  }
  &.color-gray {
      .banner-small__img {
         &:after {
            background: linear-gradient(180deg, transparentize($color-gray2, 1) 0%, $color-gray2 100%);
         }
      }
      .banner-small__inner {
         background:$color-gray;
      }
      .banner-small__heading {
         color:$color-invert;
      }
      .banner-small__description {
         color:$color-invert;
      }
   }
  &.color-light-blue {
      .banner-small__img {
         &:after {
            background: linear-gradient(180deg, transparentize($color-light-blue, 1) 0%, $color-light-blue 100%);
         }
      }
      .banner-small__inner {
         background:$color-light-blue;
      }
      .banner-small__heading {
         color:$color-primary;
      }
      .banner-small__description {
         color:$color-base;
      }
  }
  &.color-teal {
      .banner-small__img {
         &:after {
            background: linear-gradient(180deg, transparentize($color-teal, 1) 0%, $color-teal 100%);
         }
      }
      .banner-small__inner {
         background:$color-teal;
      }
      .banner-small__heading {
         color:$color-primary;
      }
      .banner-small__description {
         color:$color-base;
      }
  }
  &.no-text-underlay {
      .banner-small__img {
         &:after {
            background:none;
         }
      }
      .banner-small__inner {
         background:none;
      }
   }
}
.banner-small__img {
   height:200px;
   position: relative;
   background-size: cover;
   background-position: 100% 0;

   &:after {
      content:'';
      position: absolute;
      bottom:0;
      left:0;
      width:100%;
      height:30px;
      background: linear-gradient(180deg, transparentize(#61686E, 1) 0%, #61686E 100%);
   }
}
.banner-small__inner {
   background:#61686E;
   padding:20px;

   .button {
      margin-bottom: 5px;
   }
}

.banner-small__heading {
   @include fluid-type($bp-s, $bp-mw, 24px, 32px);
   color:$color-invert;
   margin:0 0 10px;
   line-height:1.1;
}
.banner-small__description {
   @include fluid-type($bp-s, $bp-mw, 16px, 20px);
   color:$color-invert;
   margin:0 0 15px;
}

@media (min-width: $bp-mw) {
   .banner-small-container {
      display:flex;
      justify-content: space-between;
      padding:30px 0;
   }
   .banner-small-block {
      flex:1;
   }
   .banner-small {
      height:100%;
      margin:30px auto;

      &.color-blue {
         .banner-small__inner {
            background: linear-gradient(90deg, transparentize($color-primary, .10) 70%, transparentize($color-primary, .15) 72.5%, transparentize($color-primary, .20) 75%, transparentize($color-primary, .3) 78%, transparentize($color-primary, .85) 90%, transparentize($color-primary, .95) 93%, transparentize($color-primary, .97) 96%, transparentize($color-primary, 1) 100%);
         }
      }
      &.color-gold {
         .banner-small__inner {
            background: linear-gradient(90deg, transparentize($color-gold, .10) 70%, transparentize($color-gold, .15) 72.5%, transparentize($color-gold, .20) 75%, transparentize($color-gold, .3) 78%, transparentize($color-gold, .85) 90%, transparentize($color-gold, .95) 93%, transparentize($color-gold, .97) 96%, transparentize($color-gold, 1) 100%);
         }
      }
      &.color-light-gray {
         .banner-small__inner {
            background: linear-gradient(90deg, transparentize($color-gray, .10) 70%, transparentize($color-gray, .15) 72.5%, transparentize($color-gray, .20) 75%, transparentize($color-gray, .3) 78%, transparentize($color-gray, .85) 90%, transparentize($color-gray, .95) 93%, transparentize($color-gray, .97) 96%, transparentize($color-gray, 1) 100%);
         }
      }
      &.color-gray {
         .banner-small__inner {
            background: linear-gradient(90deg, transparentize($color-gray2, .10) 70%, transparentize($color-gray2, .15) 72.5%, transparentize($color-gray2, .20) 75%, transparentize($color-gray2, .3) 78%, transparentize($color-gray2, .85) 90%, transparentize($color-gray2, .95) 93%, transparentize($color-gray2, .97) 96%, transparentize($color-gray2, 1) 100%);
         }
      }
      &.color-light-blue {
         .banner-small__inner {
            background: linear-gradient(90deg, transparentize($color-light-blue, .10) 70%, transparentize($color-light-blue, .15) 72.5%, transparentize($color-light-blue, .20) 75%, transparentize($color-light-blue, .3) 78%, transparentize($color-light-blue, .85) 90%, transparentize($color-light-blue, .95) 93%, transparentize($color-light-blue, .97) 96%, transparentize($color-light-blue, 1) 100%);
         }
      }
      &.color-teal {
         .banner-small__inner {
            background: linear-gradient(90deg, transparentize($color-teal, .10) 70%, transparentize($color-teal, .15) 72.5%, transparentize($color-teal, .20) 75%, transparentize($color-teal, .3) 78%, transparentize($color-teal, .85) 90%, transparentize($color-teal, .95) 93%, transparentize($color-teal, .97) 96%, transparentize($color-teal, 1) 100%);
         }
      }
      &.color-white {
         .banner-small__inner {
            background: linear-gradient(90deg, transparentize($color-invert, .10) 70%, transparentize($color-invert, .15) 72.5%, transparentize($color-invert, .20) 75%, transparentize($color-invert, .3) 78%, transparentize($color-invert, .85) 90%, transparentize($color-invert, .95) 93%, transparentize($color-invert, .97) 96%, transparentize($color-invert, 1) 100%);
         }
      }
      &.no-text-underlay {
         .banner-small__inner {
            background:none;
         }
      }

      &.right {
         display:flex;
         justify-content: flex-end;

         &.color-blue {
            .banner-small__inner {
               background: linear-gradient(-90deg, transparentize($color-primary, .10) 70%, transparentize($color-primary, .15) 72.5%, transparentize($color-primary, .20) 75%, transparentize($color-primary, .3) 78%, transparentize($color-primary, .85) 90%, transparentize($color-primary, .95) 93%, transparentize($color-primary, .97) 96%, transparentize($color-primary, 1) 100%);
            }
         }
         &.color-gold {
            .banner-small__inner {
               background: linear-gradient(-90deg, transparentize($color-gold, .10) 70%, transparentize($color-gold, .15) 72.5%, transparentize($color-gold, .20) 75%, transparentize($color-gold, .3) 78%, transparentize($color-gold, .85) 90%, transparentize($color-gold, .95) 93%, transparentize($color-gold, .97) 96%, transparentize($color-gold, 1) 100%);
            }
         }
         &.color-light-gray {
            .banner-small__inner {
               background: linear-gradient(-90deg, transparentize($color-gray, .10) 70%, transparentize($color-gray, .15) 72.5%, transparentize($color-gray, .20) 75%, transparentize($color-gray, .3) 78%, transparentize($color-gray, .85) 90%, transparentize($color-gray, .95) 93%, transparentize($color-gray, .97) 96%, transparentize($color-gray, 1) 100%);
            }
         }
         &.color-gray {
            .banner-small__inner {
               background: linear-gradient(-90deg, transparentize($color-gray2, .10) 70%, transparentize($color-gray2, .15) 72.5%, transparentize($color-gray2, .20) 75%, transparentize($color-gray2, .3) 78%, transparentize($color-gray2, .85) 90%, transparentize($color-gray2, .95) 93%, transparentize($color-gray2, .97) 96%, transparentize($color-gray2, 1) 100%);
            }
         }
         &.color-light-blue {
            .banner-small__inner {
               background: linear-gradient(-90deg, transparentize($color-light-blue, .10) 70%, transparentize($color-light-blue, .15) 72.5%, transparentize($color-light-blue, .20) 75%, transparentize($color-light-blue, .3) 78%, transparentize($color-light-blue, .85) 90%, transparentize($color-light-blue, .95) 93%, transparentize($color-light-blue, .97) 96%, transparentize($color-light-blue, 1) 100%);
            }
         }
         &.color-teal {
            .banner-small__inner {
               background: linear-gradient(-90deg, transparentize($color-teal, .10) 70%, transparentize($color-teal, .15) 72.5%, transparentize($color-teal, .20) 75%, transparentize($color-teal, .3) 78%, transparentize($color-teal, .85) 90%, transparentize($color-teal, .95) 93%, transparentize($color-teal, .97) 96%, transparentize($color-teal, 1) 100%);
            }
         }
         &.color-white {
            .banner-small__inner {
               background: linear-gradient(-90deg, transparentize($color-invert, .10) 70%, transparentize($color-invert, .15) 72.5%, transparentize($color-invert, .20) 75%, transparentize($color-invert, .3) 78%, transparentize($color-invert, .85) 90%, transparentize($color-invert, .95) 93%, transparentize($color-invert, .97) 96%, transparentize($color-invert, 1) 100%);
            }
         }
         &.no-text-underlay {
            .banner-small__inner {
               background:none;
            }
         }
      }
   }
   .banner-small__img { 
      display:none;
   }
   .banner-small__inner {
      min-height:440px;
      height:100%;
      background: linear-gradient(90deg, transparentize(#61686E, .10) 70%, transparentize(#61686E, .5) 90%, transparentize(#61686E, 1) 100%);
      padding:45px;
      width:390px;
   }
   .banner-small__heading {
      @include fluid-type($bp-s, $bp-mw, 24px, 32px);
      color:$color-invert;
      margin:0 0 10px;
   }
   .banner-small__description {
      @include fluid-type($bp-s, $bp-mw, 16px, 20px);
      color:$color-invert;
      margin:0 0 15px;
   }


   
   .banner-small--light {
      .banner-small__inner {
         background: linear-gradient(90deg, transparentize(#F9F2E7, .10) 70%, transparentize(#F9F2E7, .5) 90%, transparentize(#F9F2E7, 1) 100%);
      }
   }
}


/* rail-callout banner */

.rail-callout {
   border-radius: 4px;
   overflow: hidden;
   margin:0 0 30px;
   text-align: center;
   max-width:400px;
   margin:0 auto 30px;
   box-shadow:0px 3px 6px -2px #333;

   &.color-blue {
      .rail-callout__content {
         background: $color-primary;
      }
      .rail-callout__heading {
          color:$color-invert;
      }
      .rail-callout__heading-sub {
          color:$color-invert;
      }
  }
  &.color-gold {
      .rail-callout__content {
         background: $color-gold;
      }
      .rail-callout__heading {
          color:$color-primary;
      }
      .rail-callout__heading-sub {
          color:$color-base;
      }
  }
  &.color-white {
      .rail-callout__content {
         background: $color-invert;
      }
      .rail-callout__heading {
          color:$color-primary;
      }
      .rail-callout__heading-sub {
          color:$color-base;
      }
  }
  &.color-light-gray {
      .rail-callout__content {
         background: $color-gray;
      }
      .rail-callout__heading {
          color:$color-primary;
      }
      .rail-callout__heading-sub {
          color:$color-base;
      }
  }
  &.color-gray {
      .rail-callout__content {
         background: $color-gray2;
      }
      .rail-callout__heading {
         color:$color-invert;
      }
      .rail-callout__heading-sub {
         color:$color-invert;
      }
   }
  &.color-light-blue {
      .rail-callout__content {
         background: $color-light-blue;
      }
      .rail-callout__heading {
          color:$color-primary;
      }
      .rail-callout__heading-sub {
          color:$color-base;
      }
  }
  &.color-teal {
      .rail-callout__content {
         background: $color-teal;
      }
      .rail-callout__heading {
          color:$color-primary;
      }
      .rail-callout__heading-sub {
          color:$color-base;
      }
  }
  &.no-text-underlay {
      .rail-callout__content {
         background:none;
      }
   }
}
.rail-callout__image {
   height:200px;
   background-size: cover;
   border-bottom:4px solid $color-secondary-lightest;
   border-radius: 4px 4px 0 0;
}
.rail-callout__content {
   padding:20px 20px 30px;
   background:$color-primary;
}
.rail-callout__heading {
   color:$color-invert;
   @include x-rem(font-size, 24px);
   margin:0 0 10px;
}
.rail-callout__heading-sub {
   color:$color-invert;
   @include x-rem(font-size, 20px);
   font-family: $font-primary;
   font-weight:normal;
   margin:0 0 20px;
}
.rail-callout__button-container {
   margin:0 0 20px;

   &:last-child {
      margin:0;
   }

   .button {
      width:100%;
      max-width:220px;
   }
}


//Banner with cards 

.banner-with-cards {
   position: relative;
   padding:0 0 30px;

   &.color-blue {
      .banner-with-cards__card {
         background:$color-primary;
         color:$color-invert;

         &:after {
            border-right: 40px solid $color-secondary
         }
      }
      .banner-with-cards__card-title { 
         color:$color-invert;
      }
  }
   &.color-gold {
      .banner-with-cards__card {
         background:$color-gold;
         color:$color-primary;

         &:after {
            border-right: 40px solid $color-primary
         }
      }
      .banner-with-cards__card-title { 
         color:$color-primary;
      }
  }
  &.color-white {
      .banner-with-cards__card {
         background:$color-invert;
         color:$color-primary;

         &:after {
            border-right: 40px solid $color-primary
         }
      }
      .banner-with-cards__card-title { 
         color:$color-primary;
      }
  }
  &.color-light-gray {
      .banner-with-cards__card {
         background: $color-gray;
         color:$color-primary;

         &:after {
            border-right: 40px solid $color-primary
         }
      }
      .banner-with-cards__card-title { 
         color:$color-primary;
      }
  }
  &.color-gray {
      .banner-with-cards__card {
         background:$color-gray2;
         color:$color-invert;

         &:after {
            border-right: 40px solid $color-secondary
         }
      }
      .banner-with-cards__card-title { 
         color:$color-invert;
      }
   }
  &.color-light-blue {
      .banner-with-cards__card {
         background: $color-light-blue;
         color:$color-primary;

         &:after {
            border-right: 40px solid $color-primary
         }
      }
      .banner-with-cards__card-title { 
         color:$color-primary;
      }
  }
  &.color-teal {
     .banner-with-cards__card {
         background: $color-teal;
         color:$color-primary;

         &:after {
            border-right: 40px solid $color-primary
         }
      }
      .banner-with-cards__card-title { 
         color:$color-primary;
      }
  }
}
.banner-with-cards__image {
   background-size: cover;
   background-position: 50% 50%;
   height:300px;
   border-bottom:4px solid $color-secondary-lightest;
}
.banner-with-cards__content {
   background:#ffffff;
   position: relative;
   top:-30px;
   padding:20px;
   box-shadow: 0 2px 8px -3px #555;
}
.banner-with-cards__title {

}
.banner-with-cards__description {
   margin:0 0 30px;
}
.banner-with-cards__card-listing {
   list-style: none;
   margin:0;
   padding:0;
}
.banner-with-cards__card-item {
   margin:0 0 20px;
}
.banner-with-cards__card {
   background:$color-primary;
   display:block;
   text-decoration: none;
   border-radius: 4px;
   box-shadow: 0 2px 8px -3px #777;
   color:$color-invert;
   padding:20px;
   position: relative;
   overflow: hidden;
   height:100%;

   &:after {
      content:'';
      position: absolute;
      bottom:0;
      right:0;
      border-right:40px solid $color-secondary;
      border-top:40px solid transparent;
   }
}
.banner-with-cards__card-title {
   color: $color-invert;
   @include x-rem(font-size, 24px);
   margin:0 0 15px;
   font-family:$font-secondary;
}
.banner-with-cards__card-title-icon {
   fill:currentColor;
   width:20px;
   height:20px;
   vertical-align: middle;
}
.banner-with-cards__card-description {
   
}
@media (min-width: $bp-m) {   
   .banner-with-cards__card-listing {
      display:flex;
      flex-wrap: wrap;
      margin:0 -10px;
   }
   .banner-with-cards__card-item {
      width:50%;
      padding:0 10px;
   }
}
@media (min-width: $bp-mw) {
   .banner-with-cards {
      padding:50px 0 130px;
   }
   .banner-with-cards__image {
      position: absolute;
      height:auto;
      width:50%;
      right:0;
      top:50px;
      bottom:130px;
      border:0;
   }
   .banner-with-cards__content {
      width:50%;
      padding:0 60px 0 0;
      position: relative;
      top:auto;
      box-shadow: none;

      &:after {
         content:'';
         position: absolute;
         width:100%;
         left:80px;
         top:30px;
         background:$color-secondary-lightest;
         height:4px;
      }
   }
   .banner-with-cards--image-right {
      .banner-with-cards__image {
         right:auto;
         left:0;
      }
      .banner-with-cards__content {
         width:50%;
         padding:0 0 0 60px;
         float:right;
   
         &:after {
            right:80px;
            left:auto;
         }
      }
      .banner-with-cards__title {
         padding:0 0 0 10px;
         display:block;
      }
      .banner-with-cards__card-listing {
         clear:both;
      }
   }
   .banner-with-cards__title {
      display:inline-block;
      position: relative;
      padding:0 10px 0 0;
      z-index:2;
      background:#ffffff;
   }
   .banner-with-cards__description {
      @include x-rem(font-size, 20px);
   }
   .banner-with-cards__card-listing {
      display:flex;
      position: relative;
      bottom:-80px;
      margin:0;
      flex-wrap: nowrap;
      margin-top:-20px;
   }
   .banner-with-cards__card-item {
      width:25%;
      margin:0 10px;
      padding:0;

      &:first-child {
         margin-left:0;
      }
      &:last-child {
         margin-right:0;
      }
   }
   
   .banner-with-cards__card-title {
   
   }
}

@media (min-width: $bp-l) {
   .banner-with-cards__title {
      @include x-rem(font-size, 50px);
   }
   .banner-with-cards__card-item {
      margin:0 30px;
   }
   .banner-with-cards__card {
      padding:35px 40px;
   }
}