.rating-carousel {
    position: relative;
    padding: 40px 0;

    @include breakpoint($bp-mw) {
        padding: 60px 50px;
    }

    h2 {
        text-align: center;
        margin-bottom: 40px;
    }
}

.rating-carousel__container {
    display: flex;

    & > * {
        width: 100%;
        flex: 0 0 auto;
        padding: 0 20px;
    }
}

.rating-carousel-arrow {
    @extend %carousel-btn;

    &--prev {
        left: -15px;
    }

    &--next {
        right: -15px;
    }
}

.rating-carousel__nav {
    display: flex;
    gap: 50px;
    justify-content: center;
    margin: 20px 0;

    .rating-carousel-arrow {
        position: static;
        transform: none;

        @include breakpoint($bp-mw) {
            position: absolute;
            transform: translateY(-50%);
        }
    }
}

.rating-carousel__star-rating {
    text-align: center;

    svg {
        width: 30px;
        height: 30px;
        margin-right: 2px;
        fill: #FC8403;
    }
}

.rating-carousel__content {
    max-width: 800px;
    margin: auto;
}

.rating-carousel__author {
    font-weight: $fontWeight-bold;
    color: $color-primary;
}

.rating-carousel__list {
    @include resetList;
    padding: 20px;
    background-color: $color-gray;
    border-radius: 8px;
    margin-top: 20px;
}

.rating-carousel__list-item {
}

.rating-carousel__content {
}

.section-container {
	&.section-container--dark-blue {
		.rating-carousel,
		.rating-carousel__heading,
		.rating-carousel__author, 
		.rating-carousel-arrow {
			color: $color-invert;
		}

		.rating-carousel__list {
			background-color: $color-primary;
			border: 2px solid $color-invert;
		}
	}
}