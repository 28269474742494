/* ================================================================
   Alerts
   ================================================================ */

.site-alert-container {
	background:$color-primary;
	padding:15px 0;

	&.color-blue {
        background:$color-primary;
  
        .site-alert__description {
           color:$color-invert;
        }
    }
     &.color-gold {
        background:$color-gold;
  
        .site-alert__description {
           color:$color-base;
		}
		.site-alert__close { 
			border-color:$color-primary;

			&:before, &:after {
				background:$color-primary;
			}
		}
    }
    &.color-white {
        background:$color-invert;
  
        .site-alert__description {  
           color:$color-base;
		}
		.site-alert__close { 
			border-color:$color-primary;

			&:before, &:after {
				background:$color-primary;
			}
		}
    }
    &.color-light-gray {
        background: $color-gray;

        .site-alert__description { 
           color:$color-base;
		}
		.site-alert__close { 
			border-color:$color-primary;

			&:before, &:after {
				background:$color-primary;
			}
		}
    }
    &.color-gray {
        background: $color-gray2;

        .site-alert__description { 
           color:$color-invert;
        }
    }
    &.color-light-blue {
         background: $color-light-blue;

		 .site-alert__description { 
           color:$color-base;
        }
    }
    &.color-teal {
        background: $color-teal;
  
        .site-alert__description {
           color:$color-base;
        }
	}
	&.color-red {
        background: rgb(124, 1, 1);

        .site-alert__description { 
           color:$color-invert;
        }
    }
}
.site-alert {
	display:flex;
	align-items: center;
	padding-right:30px;
	position: relative;

	+ .site-alert {
		margin:20px 0 0 0;
	}
}
.site-alert__image {
	max-width:84px;
	padding:0 20px 0 0;
}
.site-alert__description {
	width:100%;
	color:$color-invert;
	@include x-rem(font-size, 18px);
	margin:0;

	p {

		&:last-child {
			margin:0;
		}
	}

	a {
		text-decoration: underline;
		color:inherit;
		font-weight:bold;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}
.site-alert__close {
	border:2px solid $color-invert;
	border-radius: 50%;
	width:26px;
	height:26px;
	padding:0;
	position: absolute;
	background: transparent;
	top:-2px;
	right:-5px;
	cursor: pointer;

	&:before {
		content:'';
		position: absolute;
		width:14px;
		height:2px;
		background:$color-invert;
		transform: rotate(45deg);
		top:10px;
		left:4px;
	}
	&:after {
		content:'';
		position: absolute;
		width:2px;
		height:14px;
		background:$color-invert;
		transform: rotate(45deg);
		top:4px;
		left:10px;
	}

	&:hover,
	&:focus {
		background:$color-invert;

		&:before,
		&:after {
			background:$color-primary;
		}
	}
}