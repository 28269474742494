.promotion-listing-box {
  background: $color-gold2;
}
.promotion-listing-box__title {
  border-bottom: 1px solid $color-primary;
  @include x-rem(margin-bottom, 64px);
  @include x-rem(padding-bottom, 8px);
}
.promotion-listing1 {
  margin: 0;
  padding: 0;
  @include breakpoint(62em) {
    column-count: 4;
    column-gap: 30px;
  }
}
.promotion-listing1__item {
  list-style-type: circle;
  @include x-rem(padding, 1.6px 3.2px);
  @include x-rem(margin, 0 0 0 24px);
}
.promotion-listing1__item--block {
  list-style: none; 
  margin-left: 0;
}
.promotion-listing1__block {
  background: $color-primary;
  list-style: none;
  @include x-rem(padding, 0px 4px 0px 20px);
  @include x-rem(margin, 0 0 24px);
  @include x-rem(min-height, 29px); 
  display: flex;
  align-items: center;
}
.promotion-listing__location-title {
  color: $color-invert;
  font-weight: $fontWeight-superBold;
  text-transform: uppercase;
  @include x-rem(font-size, 13px);
  line-height: initial;
  margin-bottom: 0;
  font-family: $font-primary;

}
.promotion-listing__name {
  color: $color-primary;
  @include x-rem(font-size, 13px);
  letter-spacing: 0.05rem;
  line-height: 1.45em;
  margin-bottom: 4px;

  &--bold{
    text-transform: uppercase;
    font-weight: $fontWeight-superBold;
  }
}
