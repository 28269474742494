.newsletter-carousel-container {
  width: 100%;
  @include x-rem(max-width, 1620px);
  margin-left: auto;
  padding-left: $grid-gutter;
}
.newsletter-listing-slider {
  position: relative;
  .newsletter-listing__container {
    display: flex;
    @include x-rem(gap, 16px);

    @include breakpoint($bp-m) {
      @include x-rem(gap, 50px);
    }
  }
  .a11y-slider-container {
    display: flex;
    overflow: hidden;
    @include x-rem(padding-left, 16px);
  }

  .newsletter-listing__item {
    width: 80%;
    flex: 0 0 auto;
    @include breakpoint($bp-m) {
      width: 50%;
      padding-left: 0;
    }
    @include breakpoint(80em) {
      width: 30.333%;
    }
  }
  .a11y-slider--small {
    .newsletter-listing__item {
      @include breakpoint(80em) {
        width: 30.333%;
      }
    }
  }

  .newsletter-listing__arrow {
    @extend %carousel-btn;
    background: $body-bg;
    border-radius: 50%;
    box-shadow: 0 -8px 32px 0 rgba(0, 0, 0, 0.24);
    @include x-rem(height, 40px);
    @include x-rem(width, 40px);

    svg {
      @include x-rem(height, 30px);
    }
    &--prev {
      left: -10px;
    }
    &--next {
      right: 10px;
    }

    &:hover,
    &:focus {
      svg {
        transform: scale(1.4);
      }
    }

    @include breakpoint($bp-m) {
      @include x-rem(height, 72px);
      @include x-rem(width, 72px);
      &--prev {
        left: 0;
      }
      &--next {
        right: 0;
      }
    }
    @include breakpoint($bp-xxl) {
      @include x-rem(height, 72px);
      @include x-rem(width, 72px);
      &--prev {
        left: -20px;
      }
      &--next {
        right: 15px;
      }
    }
  }
  .a11y-slider-dots {
    justify-content: center;
    gap: 1rem;
    @include x-rem(margin-top, 48px);
    button {
      border: 3px solid $color-primary;
      background: $body-bg;

      &.active {
        background: $color-primary;
      }
    }

    @include breakpoint($bp-m) {
      justify-content: flex-start;
    }
  }
}

.newsletter-listing__item {
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.newsletter-listing__imgbox {
  @include bgImg;
  background-position: initial;
  flex-shrink: 0;
  width: 100%;
  @include x-rem(height, 188px);
  @include breakpoint($bp-m) {
    @include x-rem(height, 255px);
  }
}
.newsletter-listing__content {
  position: relative;
  background: $color-primary;
  @include x-rem(padding, 15px 14px 37px 14px);
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: $color-secondary-light2;
  }
  @include breakpoint($bp-m) {
    @include x-rem(padding, 23px 25px 31px 25px);

    &::before {
      height: 2px;
    }
  }
}
.newsletter-listing__top {
  @include x-rem(margin-bottom, 7px);
  color: $color-secondary-light2;
  font-weight: bold;
  @include x-rem(font-size, 13px);
  @include x-rem(line-height, 18px);
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 14px);
    @include x-rem(font-size, 14px);
    @include x-rem(line-height, 22px);
  }
}
.newsletter-listing__link {
  &:hover .newsletter-listing__title,
  &:focus .newsletter-listing__title {
    text-decoration: underline;
  }
}
.newsletter-listing__title {
  color: $color-invert;
  margin: 0;
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 20px);
  font-family: $font-tertiary;
  @include breakpoint($bp-m) {
    @include x-rem(font-size, 20px);
    @include x-rem(line-height, 26px);
  }
}
.newsletter-listing__icon {
  @include x-rem(height, 12px);
  @include x-rem(width, 7.7px);
  margin-left: 5px;
  @include breakpoint($bp-m) {
    @include x-rem(height, 16px);
    @include x-rem(width, 11px);
  }
}
