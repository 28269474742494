
.landing-search {
    max-width: 860px;
    margin:0 auto;
    position: relative;
}
.landing-search__input {
    border:1px solid #D8D8D8;
    border-radius: 4px;
    width:100%;
    height:55px;
    padding:10px 60px 10px 20px;

    &:hover,
    &:focus {
        box-shadow: 0 0 0.625rem rgba(0,0,0,0.1);
        border:1px solid #D8D8D8;
    }
}
.landing-search__button {
    position: absolute;
    right:15px;
    top:0;
    height:100%;
    width:40px;
    border:0;
    padding:0;
    background:none;
    cursor: pointer;
    color:#002147; 

    &:hover,
    &:focus {
        opacity: .7; 
    }
}
.landing-search__button-icon {
    width:36px;
    height:36px;
    position: absolute;
    top:50%;
    left:50%;
    fill:currentColor;
    transform: translateX(-50%) translateY(-50%);
}