.callouts-container {
    background:$color-gray;
}
.callouts-listing {
    @include resetList;
    margin:0 -15px;
}
.callouts-listing__item {
    border-bottom:1px solid #999;
}
.callout-item {
    display:flex;
    align-items: center;
    padding:15px 20px;
    color:$color-primary;
    text-decoration: none;
    font-family: $font-primary;
    font-weight:bold; 

    &:hover,
    &:focus {
        background:$color-secondary;
        color:$color-invert;
    }
}
.callout-item__icon,
.callout-item__icon-container {
    display:none;
}
.callout-item__arrow {
    width:18px;
    height:18px;
    fill:currentColor;
    margin:0 0 0 5px;
}
.callout-item__text {

}

@media (min-width: $bp-m) {
    .callouts-listing {
        display:flex;
        flex-wrap: wrap;
    }
    .callouts-listing__item {
        width:50%;

        &:nth-child(2n-1) {
            border-right:1px solid #999;
        }
    }
}
@media (min-width: $bp-mw) {
    .callouts-container {
        background:$color-primary;
        padding:30px 0;
        margin:0 0 40px;

        &.color-gold {
            background: $color-gold;
    
            .callout-item {
                color:$color-primary;
            }
        }
        &.color-white {
            background: $color-invert;
    
            .callout-item {
                color:$color-primary;
            }
        }
        &.color-light-gray {
            background: $color-gray;
    
            .callout-item {
                color:$color-primary;
            }
        }
        &.color-gray {
            background: $color-gray2;
    
            .callout-item {
                color:$color-invert;
            }
        }
        &.color-light-blue {
            background: $color-light-blue;
    
            .callout-item {
                color:$color-primary;
            }
        }
        &.color-teal {
            background: $color-teal;
    
            .callout-item {
                color:$color-primary;
            }
        }
        &.no-text-underlay {
            background:none;
        }
    }
    .callouts-listing { 
        flex-wrap: nowrap;
        justify-content: center;
    }
    .callouts-listing__item {
        width:100%;
        max-width:220px;
        border:0 !important;
        padding:0 2px;
    }
    .callout-item {
        color:$color-invert;
        display:block;
        text-align: center;

        &:hover,
        &:focus {
            background:none;
            text-decoration: underline;
        }
    }
    .callout-item__icon { 
        display:inline-block;
        fill:$color-secondary-light;
        width:75px;
        height:75px;
    }
    .callout-item__icon-container {
        width:75px;
        display:inline-block;
    }
    .callout-item__arrow {
        display:none;
    }
    .callout-item__text {
        display:block;
        font-weight:500;
        padding:10px 0 0 0;
    }
}

/* ribbons */

.text-button-callout {
    background:$color-primary;
    text-align: center;
    padding:30px 0;

    &.color-blue {
        background: $color-primary;

        .text-button-callout__heading {
            color:$color-invert;
        }
        .text-button-callout__description {
            color:$color-invert;
        }
    }
    &.color-gold {
        background: $color-gold;

        .text-button-callout__heading {
            color:$color-primary;
        }
        .text-button-callout__description {
            color:$color-base;
        }
    }
    &.color-white {
        background: $color-invert;

        .text-button-callout__heading {
            color:$color-primary;
        }
        .text-button-callout__description {
            color:$color-base;
        }
    }
    &.color-light-gray {
        background: $color-gray;

        .text-button-callout__heading {
            color:$color-primary;
        }
        .text-button-callout__description {
            color:$color-base;
        }
    }
    &.color-gray {
        background: $color-gray2;

        .text-button-callout__heading {
            color:$color-invert;
        }
        .text-button-callout__description {
            color:$color-invert;
        }
    }
    &.color-light-blue {
        background: $color-light-blue;

        .text-button-callout__heading {
            color:$color-primary;
        }
        .text-button-callout__description {
            color:$color-base;
        }
    }
    &.color-teal {
        background: $color-teal;

        .text-button-callout__heading {
            color:$color-primary;
        }
        .text-button-callout__description {
            color:$color-base;
        }
    }
    &.no-text-underlay {
        background:none;
    }
}
.text-button-callout__container {

    &:before,
    &:after {
        display:none;
    }
}
.text-button-callout__heading {
    color:$color-invert;
    @include x-rem(font-size, 24px);
    margin:0 0 10px;
}
.text-button-callout__description {
    color:$color-invert;
    margin:0 0 20px;
    @include x-rem(font-size, 20px);
}

@media (min-width: $bp-mw) {
    .text-button-callout {
        text-align: left;
        padding:35px 0;
    }
    .text-button-callout__container {
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
    .text-button-callout__heading {
        margin:0;
    }
    .text-button-callout__description {
        padding:0 20px;
        margin:0;
    }
    .text-button-callout__button {
        white-space: nowrap;
    }
}

.text-icon-callout {
    background:$color-gray;
    padding:30px 0;

    &.color-blue {
        background:$color-primary;
  
        .text-icon-callout__content { 
           color:$color-invert;
        }
    }
     &.color-gold {
        background:$color-gold;
  
        .text-icon-callout__content {
           color:$color-base;
        }
    }
    &.color-white {
        background:$color-invert;
  
        .text-icon-callout__content {  
           color:$color-base;
        }
    }
    &.color-light-gray {
        background: $color-gray;

        .text-icon-callout__content { 
           color:$color-base;
        }
    }
    &.color-gray {
        background: $color-gray2;

        .text-icon-callout__content { 
           color:$color-invert;
        }
    }
    &.color-light-blue {
         background: $color-light-blue;

        .text-icon-callout__content {  
           color:$color-base;
        }
    }
    &.color-teal {
        background: $color-teal;
  
        .text-icon-callout__content {  
           color:$color-base;
        }
    }
    &.no-text-underlay {
        background:none;
    }
}
.text-icon-callout__container {
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.text-icon-callout__image-container {
    width:62px;
}
.text-icon-callout__content {
    padding:0 0 0 30px;
    width:calc(100% - 62px);
}

// Image text callout

.icon-text-callout-container {
    list-style:none;
    padding:0;
    margin:0;
}
.icon-text-callout__item {
    margin:0 0 30px;
    padding:40px 0 0 0;
}
.icon-text-callout {
    background:$color-invert;
    box-shadow: 0 3px 7px -1px #777;
    height:100%;
    padding:20px;
    text-align: center;
    display:flex;
    flex-flow: column;
    align-items: center;

    .button {
        margin-top:auto;
    }
}
.icon-text-callout__image {
    width: 100px;
    height:100px;
    padding:15px;
    border-radius: 50%;
    margin:0 auto -50px;
    position: relative;
    top:-70px;
    background:$color-invert;
    box-shadow: 0 3px 7px -1px #777;
}
.icon-text-callout__image-inner {
    width:70px;
    height:70px;
    background-size: cover;
    background-position: 50% 50%;
}
.icon-text-callout__heading {
    @include x-rem(font-size, 20px);
    font-family: $font-primary;
    margin:0 0 20px;
}
.icon-text-callout__description {
    margin:0 0 20px;
}

@media (min-width: $bp-m) {
    .icon-text-callout-container {
        display:flex;
        flex-wrap: wrap;
    }
    .icon-text-callout__item {
        width:50%;
        padding:40px 15px 0;
    }
    .icon-text-callout {
        padding:30px;
    }
    .icon-text-callout__description {
        @include x-rem(font-size, 20px);
    }
}
@media (min-width: $bp-l) {
    .icon-text-callout__item {
        width:33.33%;
    }
}

.alert-ribbon {
    background:#f1f1f1;
    padding:15px;
}

.alert-ribbon__heading {
    @include x-rem(font-size, 20px);
    margin:0 0 5px;
    font-family: $font-tertiary;
}
.alert-ribbon__description {
    @include x-rem(font-size, 20px);
    margin:0;
}

@media (min-width: $bp-mw) {
    .alert-ribbon__container {
        display:flex;
        align-items: flex-end;
        justify-content: center;
    }
    .alert-ribbon__heading {
        white-space: nowrap;
        margin:0
    }
    .alert-ribbon__description {
        @include x-rem(font-size, 16px);
        margin:0 0 2px 10px;
    }
}

/* Side by Side callout */

.callout-side-by-side {
    margin:0 0 30px;
}
.callout-side-by-side__img {
    height:240px;
    margin:0 0 20px;
    background-size:cover;
    background-position:50% 50%;
}
.callout-side-by-side__content {

}
.callout-side-by-side__heading {
    @include fluid-type($bp-s, $bp-mw, 24px, 32px);
}
.callout-side-by-side__description {
    @include fluid-type($bp-s, $bp-mw, 16px, 20px);
}
.callout-side-by-side__buttons {
    margin:0 -5px;

    > * {
        margin:0 5px;
    }
}
@media (min-width: $bp-m) {
    .callout-side-by-side {
        display:flex;
    }
    .callout-side-by-side__img {
        height:auto;
        width:calc(100% - 400px);
        margin:0;
    }
    .callout-side-by-side__content {
        width:400px;
        padding:30px 0 30px 30px;
    }
    .callout-side-by-side--right {
        .callout-side-by-side__img {
            order:2;
        }
        .callout-side-by-side__content {
            padding:30px 30px 30px 0;
        }
    }
}