.side-by-side-container {
    overflow: hidden;
    margin: 0 0 30px;
    position: relative;
}

.side-by-side {
    
}

.side-by-side__block {
    margin:0 0 30px;
    padding:0 0 30px;
    border-bottom:1px solid #999;

    &:last-child {
        margin:0;
        padding:0;
        border:0;
    }
}

@media (min-width: $bp-m) {
    .side-by-side-container {
        margin: 0 0 50px;
    }
    .side-by-side {
        display: flex;
        justify-content: space-between;
        margin:0 -40px;
    }
    .side-by-side__block {
        padding:0 50px;
        margin:0;
        border:0;
        border-right:1px solid #999;

        &:last-child {
            border:0;
            padding:0 50px;
        }
    }
}


.side-by-side-slider {
    .a11y-slider-container {
        overflow: hidden;
    }
    .side-by-side {
        display:flex;
    }

    .side-by-side__block {
        width:100%;
        flex: 0 0 auto;
        padding:0 75px;
    }
}
.side-by-side-arrow {
    @extend %carousel-btn;

    &--prev { left: -15px; }
    &--next { right: -15px; }
}
@media (max-width: $bp-m-max) {
    .side-by-side-slider {
        .side-by-side__block {
            border:0;
        }
    }
}
@media (min-width: $bp-mw) {
    .side-by-side-slider{
        padding:0 50px;
    
        .side-by-side__block {
            width:50%;
            flex: 0 0 auto;
            padding:0 50px;

            p {
                @include x-rem(font-size, 20px);
            }
        }
    }
    .side-by-side__description {
        @include x-rem(font-size, 20px);
    }
}