.expandable-area {
    border:1px solid #ccc;
    border-radius: 4px;
    box-shadow:0px 3px 6px -2px #333;
    margin:30px 0;

    &:first-child {
        margin-top:0;
    }
}
.expandable-area__button {
    padding:15px;
    position: relative;
    border:0;
    background:transparent;
    width:100%;
    color:$color-primary;
    font-family: $font-secondary;
    @include x-rem(font-size, 24px);
    font-weight:bold;
    text-align: left;
    line-height:1.1;
    cursor: pointer;

    &:before {
        content:'';
        width:16px;
        height:3px;
        background:currentColor;
        position: absolute;
        top:50%;
        right:8px;
        transform: translateX(-50%) translateY(-50%);
    }
    &:after {
        content:'';
        width:3px;
        height:16px;
        background:currentColor;
        position: absolute;
        top:50%;
        right:21px;
        transform: translateX(-50%) translateY(-50%);
    }

    &.active {
        &::after {
            display:none;
        }
    }

    &:hover,
    &:focus {
        color:$color-secondary;
    }
}
.expandable-area__static-heading {
    @extend .expandable-area__button;
    cursor: auto;

    &:before,
    &:after {
        display:none;
    }
    &:hover,
    &:focus {
        color:$color-primary;
    }
}
.expandable-area-content {
    padding:0 15px 15px;

    p:last-child {
        margin:0;
    }
}

@media (min-width: $bp-mw) {
    .expandable-area {
        margin:40px 0;
    }
    .expandable-area__button {
        padding:30px 35px;
        @include x-rem(font-size, 32px);

        &:before {
            width:18px;
            right:20px;
        }
        &:after {
            height:18px;
            right:35px;
        }
    }
    .expandable-area-content {
        padding:0 40px 40px;
        @include x-rem(font-size, 20px);
    }
}