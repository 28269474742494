.site-header {
    position: relative;
    background:$color-primary;
}

.header-helper-links,
.header-helper-links[data-expander-target] {
    display:none;
}

.top-navigation {
    display:none;
}

.header-search-trigger {
    @include resetBtn;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 0;
    color: $color-invert;
    flex: 0 0 auto;

    &:hover,
    &:focus {
        color: $color-secondary-light;
    }

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }

    &.header-search-trigger__desktop {
        display:none;
    }
}

.header-search {
    padding:20px;
    display:flex;
}

.header-search__input {
    display: block;
    width: 100%;
    height: 46px;
    border:1px solid #bbb;

    @include placeholder {
      color: $color-base;
      opacity: 1;
    }
}
.button.header-search__submit {
    border-radius: 0 4px 4px 0;
}

.header-bottom {
    padding:10px 0;
    display:flex;
    align-items: center;
    flex-wrap: wrap;
}

.header-logo {
    margin: 0 auto;
    font-size: 0;
    max-width:200px;
	width: 100%;

    a {
        display: block;
    }
}
.header-logo__large{
    margin: 0;
    margin-right: auto;
}
.header-button-login-container {

}

.button.button-login {
    display:flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @include x-rem(font-size, 13px);
    padding:8px 12px;
}
.button-login__svg {
    width:18px;
    height:24px;
    fill:currentColor;
    margin:0 10px 0 0;
}
.login-flyout {
    position: absolute;
    top:100%;
    width:100%;
    left:0;
    padding:25px;
    background:$color-gray;
    z-index:100;
    box-shadow: 0 4px 7px -4px #333;
    display:none;

    &.active:before {
        content:'';
        position: absolute;
        top:-8px;
        right:20px;
        transform: translateX(-50%);
        border-bottom:12px solid $color-gray;
        border-left:11px solid transparent;
        border-right:11px solid transparent;
    }

    @media all and (min-width : $bp-l) and (max-width: $bp-xxl)
    {
        padding: 15px 25px;
    }
}
.login-flyout__heading {
    color:$color-primary;
    margin:0 0 15px;
    display:flex;
    align-items: center;
    
    @media all and (min-width : $bp-l) and (max-width: $bp-xxl)
    {
        margin:0 0 5px;
    }
}
.login-flyout__heading__svg {
    width:22px;
    height:28px;
    fill:$color-secondary;
    margin:0 15px 0 0;
}
.login-flyout__label {
    text-transform: uppercase;
    display: block;
    margin:0 0 3px;
    font-weight:500;
}
.login-flyout__select,
.login-flyout__input {
    width:100%;
    margin:0 0 18px;
    border:1px solid #ccc;
    border-radius: 4px;
    height:44px;

    @media all and (min-width : $bp-l) and (max-width: $bp-xxl)
    {
        margin:0 0 5px;
        height: 35px;
    }
}
.login-flyout__button {
    width:100%;
    &:hover,
    &:focus {
        text-decoration: none;
    }
}


.login-flyout__helper {
    padding:20px 0 0 0;
    text-align: center;
}
.login-flyout__helper-top {
    margin:0 0 10px;
}
.login-flyout__helper-link {
    text-decoration: none;
    margin:0 2px;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.header-navigation-container {
    position: absolute;
    top:100%;
    left:0;
    width:100%;
    z-index: 100;
    background:$color-gray;
    box-shadow: 0 4px 7px -4px #333;

    &.active:before {
        content:'';
        position: absolute;
        top:-8px;
        left:20px;
        border-bottom:12px solid $color-gray;
        border-left:11px solid transparent;
        border-right:11px solid transparent;
    }
}

.header-mobile-locations {
    width:calc(100% + 30px);
    margin:10px -15px 0;
    padding:15px 15px 5px;
    border-top:1px solid #2f4763;
    text-align: center;
}
.header-mobile-locations__link {
    color:$color-invert;

    .location-item__link-icon {
        fill:currentColor;
        float:none;
        position: relative;
        top:2px;
    }
}
.header-bottom__large .header-mobile-locations {
    display: none;
}
.header-search-mobile {
    border-bottom:1px solid #ccc;
    padding:15px;

    .yxt-SearchBar-container {
        z-index:30;
    }
}

.toggle-header-helper-links {
    display:none;
}

.other-services-heading {
    text-align: left;
    @include x-rem(font-size, 26px);
}
.other-services-parent-list {
    list-style: none;
    margin:0;
    padding:0;
}
.other-services-parent-list__item {
    margin:0 0 30px;

    &:last-child {
        margin:0;
    }
}
.other-services-sub-heading {
    text-transform: uppercase;
    font-family: $font-primary;
    @include x-rem(font-size, 16px);
    margin:0 0 7px;
}
.other-services-list {
    list-style: none;
    margin:0 0 15px;
    padding:0;

    strong {
        display:block;
        margin:0 0 5px;
    }

    .other-services-list {
        padding:0 0 0 15px;
    }
}
.other-services-list__list-item {
    margin:0 0 6px;

    a {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

@media (min-width: $bp-m) {
    .other-services-heading {
        text-align: center;
        @include x-rem(font-size, 40px);
    }
    .other-services-parent-list {
        column-count: 2;
        break-inside: avoid-column;
    }
}

@media (max-width: $bp-mw-max) {
    .js-data-toggled-login-flyout,
    .js-data-toggled-header-navigation-container,
    .js-data-toggled-header-search {
        .site-header {
            z-index:100;
        }
        .site-container:after {
            content:'';
            position: fixed;
            width:100%;
            height:100%;
            left:0;
            top:0;
            background:rgba(0,0,0,.5);
            z-index:99;
        }
    }
    .js-data-toggled-header-navigation-container {
        .button-login {
            z-index:9999;
        }
    }
    .header-helper-links {
        display:none !important;
    }
}

@media (min-width: 500px) {
    .header-logo { 
        max-width:334px;
        svg{
            @include x-rem(width, 260px); 
    
        }
    }
    .header-navigation-container,
    .login-flyout {

    }
}

@media (min-width: 93.75em) {

    .header-top {
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding:10px 0;
        border-bottom:1px solid #4D647E;
        position: relative;
    }
    .header-navigation-container__large{
        flex: 1;
        flex-shrink: 0;
    }
    
    .header-helper-links,
    .header-helper-links[data-expander-target] {
        @include resetList;
        display:flex;
    }
    .header-helper-links__item {

        &:after {
            content:'|';
            color:#8292A8;
            padding:0 5px 0 4px;
        }
        &:last-child:after {
            display:none;
        }
    }
    .header-helper-links__link {
        color: $color-invert;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .header-top__right {
        display:flex;
        justify-content: space-between;
        align-items: center;
    }

    .top-navigation {
        @include resetList;
        display:flex;
    }
    .top-navigation__item {
        margin:0 20px 0 0;
        position: relative;

        &.top-navigation__item--location {
            position: static;
        }
    }
    .top-navigation__link {
        color: $color-invert;
        text-decoration: none;
        border:0;
        padding:0;
        background:transparent;
        cursor:pointer;

        .location-item__link-icon {
            fill:currentColor;
        }

        &[data-expander] {
            position: relative;
            padding-right:20px;

            &:before {
                content:'';
                position: absolute;
                right:6px;
                top:50%;
                width:8px;
                height:2px;
                background:$color-invert;
                transform: rotate(45deg) translateY(-50%);
            }
            &:after {
                content:'';
                position: absolute;
                right:0;
                top:50%;
                width:8px;
                height:2px;
                background:$color-invert;
                transform: rotate(-45deg) translateY(-50%);
            }
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    .location-flyout {
        position: absolute;
        top:100%;
        right:0;
        background:$color-gray;
        padding:20px;
        border-radius:4px;
        z-index:200;
        display:none;

        &.active:before {
            content:'';
            position: absolute;
            top:-7px;
            left:140px;
            border-bottom:7px solid $color-gray;
            border-left:6px solid transparent;
            border-right:6px solid transparent;
        }

        .location-item__details {
            width:auto;
        }
        .location-item__details-bottom {
            justify-content: flex-start;
            @include x-rem(font-size, 14px);
        }
        .locations-item__min-width {
            min-width:210px;
        }
        .location-item__details-block p {
            @include x-rem(font-size, 14px);
        }
        .location-item__link {
            font-weight:normal;
        }
        .atm-location__titles {
            padding-top:15px;
        }
        .atm-location__heading {
            font-family: $font-primary;
            @include x-rem(font-size, 17px);
            font-weight:500;
        }
    }
    .location-flyout__heading {
        margin:0 0 10px;
        color:$color-primary;
        @include x-rem(font-size, 24px);
    }
    .location-flyout__form {
        display:flex;
        margin:0 -5px;
        padding-top:10px;
    }
    .location-flyout__input {
        border:1px solid #bbb;
        border-radius: 4px;
        height:100%;
        height:46px;
        margin:0 5px;
        min-width:260px;

        @include placeholder {
            color: $color-base;
            opacity: 1;
        }
    }
    .location-flyout__button {
        white-space: nowrap;
        margin:0 5px;
    }

    .top-navigation-flyout {
        @include resetList;
        position: absolute;
        top:calc(100% + 13px);
        left:50%;
        transform: translateX(-50%);
        background:$color-gray;
        padding:20px;
        border-radius:4px;
        z-index:1001;
        width:220px;
        display:none;

        &.active:before {
            content:'';
            position: absolute;
            top:-7px;
            left:50%;
            transform: translateX(-50%);
            border-bottom:7px solid $color-gray;
            border-left:6px solid transparent;
            border-right:6px solid transparent;
        }
    }
    .top-navigation-flyout__item {
        margin:0 0 5px;

        &:last-child {margin:0;}
    }
    .top-navigation-flyout__link {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .header-search-trigger {

        &.header-search-trigger__desktop {
            display:block;
        }
        &.header-search-trigger__mobile {
            display:none;
        }
    }

    .header-search {

    }
    .header-search {
        position: absolute;
        width:100%;
        z-index: 100;
        background:$color-gray;
        padding:20px;
        z-index:200;
        display:none;
        box-shadow: 0 4px 7px -4px #333;
        max-width:400px;
        right:-14px;
        top:100%;
        left:auto;
        border-radius: 4px;
        display:none;

        &.active {
            display:flex !important;
        }
        &.active:before {
            content:'';
            position: absolute;
            top:-12px;
            right:20px;
            border-bottom:12px solid $color-gray;
            border-left:11px solid transparent;
            border-right:11px solid transparent;
        }
    }

    .header-search-mobile {
        display:none;
    }

    .header-bottom {
        padding:30px 0;
        flex-wrap: nowrap;
        position: relative;
        align-items: flex-end;
    }

    .header-logo {
        margin: 0 20px 0 0; 
        width:33%;
    }
    .header-logo__large{
        max-width:320px;
        margin-right: auto;
    }

    .towneinsurance-site {
        .header-logo {
            margin-right: 1rem;
            width: 100%;
        }
    }

    .button-login {
        margin-left:auto;
    }

    .header-navigation-container,
    .header-navigation-container[data-expander-target] {
        display:block;
        position: static;
        box-shadow:none;
        background:none;
        width:auto;
        padding:0;

        &.active:before {
            display:none;
        }
    }

    .header-button-login-container {
        margin:0 0 0 auto;
        width:auto;
        padding:0;
        border:0;
    }
    .button.button-login {
        padding:12px 24px;
        @include x-rem(font-size, 16px);

        .button-login-mobile {
            display: none;
        }
        &:before,
        &:after {
            display:none
        }
    }
    .login-flyout {
        left:auto;
        right:-15px;
        max-width:400px;

        &.active:before {
            left:auto;
            right:45px;
            transform: none;
        }
    }

    .header-mobile-locations {
        display:none;
    }
}

@media (min-width: $bp-l) and  (max-width: $bp-l-max) {
    .toggle-header-helper-links {
        display:inline-block;
    }
    .header-helper-links,
    .header-helper-links[data-expander-target] {
        display:none;

        position: absolute;
        top:100%;
        left:0;
        background:$color-gray;
        padding:20px;
        border-radius:4px;
        z-index:1001;
        width:220px;

        &.active:before {
            content:'';
            position: absolute;
            top:-7px;
            left:50%;
            transform: translateX(-50%);
            border-bottom:7px solid $color-gray;
            border-left:6px solid transparent;
            border-right:6px solid transparent;
        }
        .header-helper-links__item {
            margin:0 0 5px;

            &:after {
                display:none;
            }
        }
        .header-helper-links__link {
            color:$color-primary;
        }
    }
}
@media (min-width: $bp-xl) {
    .header-helper-links, .header-helper-links[data-expander-target] {
        display:flex !important;
    }
}

.your-location-flyout {
    position: absolute;
    top:100%;
    right:0;
    background:$color-gray;
    padding:20px;
    border-radius:4px;
    z-index:200;
    display:none;

    &.active:before {
        content:'';
        position: absolute;
        top:-7px;
        left:140px;
        border-bottom:7px solid $color-gray;
        border-left:6px solid transparent;
        border-right:6px solid transparent;
    }
}

.page-banner.color-gray .page-banner__inner {
    background:transparentize($color: $color-gray, $amount: .35);
}

.your-location-flyout {
    position: absolute;
    top:100%;
    right:0;
    background:$color-gray;
    padding:20px;
    border-radius:4px;
    z-index:200;

    &.active:before {
        content:'';
        position: absolute;
        top:-7px;
        left:140px;
        border-bottom:7px solid $color-gray;
        border-left:6px solid transparent;
        border-right:6px solid transparent;
    }
}

.login-flyout__desktop {
    display: none;
}

.login-flyout__mobile {
    display: block;
}

.fdic-banner {
    background: $color-primary-dark;
    padding: 10px 0;
}
.fdic-banner__container {
    display: flex;
    align-items: center;
    gap: 9px;
}
.fdic-banner__img {
    flex-shrink: 0;
}

.fdic-banner__text {
    color: $color-invert;
    margin: 0;
    @include x-rem(font-size, 14px);
    line-height: 14px;
}
@media (min-width:$bp-m) {
    .login-flyout__desktop {
        display: block;
    }

    .login-flyout__mobile {
    display: none;
    }
}

.x-button {
    border: 1px solid transparent;
    background: transparent;
    width: 24px;
    height: 24px;
    right: 25px;
    position: absolute;
    top: 15px;
}

.x-button .close-icon {
    width: 24px;
    height: 24px;
}

.modaal-buttons {
    text-align: left;
    margin-bottom: 20px;

    .login-flyout__button {
        width:100%;
        margin-bottom:5px;
    }
}
.login-flyout__content, .login-flyout__header {
    text-align: left;
}

.login-flyout__content {
    margin-top: 5px;
}
.login-flyout__header {
    padding-left: .938em;
    padding-right: .938em;
}

.login-flyout__header h4 {
    border-bottom: 3px solid $color-secondary;
    padding-bottom: 10px;
}

.login-flyout__border-left {
    border-left: none;
}

.login-flyout-side-by-side {
    text-align: left;

    .login-flyout__button {
        width:100%;
    }
}

.login-flyout-side-by-side__block {


    &:first-child {
        border-bottom:1px solid $color-secondary;
        padding:0 0 20px;
        margin:0 0 20px;
    }
}

@media (min-width:$bp-m) {
    .modaal-buttons {
        .login-flyout__button {
            width:auto;
        }
    }
    .login-flyout-side-by-side {
        display:flex;
        margin:0 -10px;

        .login-flyout__button {
            width:auto;
        }
    }
    .login-flyout-side-by-side__block {
        flex:1;
        padding:0 20px;

        &:first-child {
            border:0;
            border-right:1px solid $color-secondary;
            padding:0 20px;
            margin:0;
        }
    }
}
@media (min-width:$bp-l) {
    .header-bottom__large{
        display: flex; 
        align-items: center; 
        flex-wrap: wrap;
        .header-search {
            top: 68%;
        }
        .header-logo__large{
            width: 100%;
            @include x-rem(max-width, 300px); 
            margin: 0;
            margin-right: auto;
           
        }
        
        
    }
     
}
@media (min-width: 93.75em) {
    .header-bottom__large{
        @include x-rem(padding, 58px 10px );
    }
    .header-navigation-container__large[data-expander-target]{
        display: flex;
        align-items: center;
        @include x-rem(gap, 20px);
        .header-search-trigger {
            display: flex;
        }
        .cm-menu{
            @include x-rem(gap, 7px);
        }
    }
    .header-bottom__large{
        flex-wrap: nowrap;
    .header-logo__large{
        width: 33%;
        @include x-rem(max-width, 320px); 
        @include x-rem(margin, 0 40px 0 0); 
    }
     
    
}
}

 