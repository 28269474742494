/* ******************************************************
   NAVIGATION
        - Footer Navigation
   ****************************************************** */
.footer-logo {
    image {
        width: 100%;
    }
}

@media (min-width:$bp-mw){
    .footer-nav {
        .menu {
            display:table;
            list-style:none;
            width:100%;

            > li { display:table-cell;
                ul {
                    list-style:none;
                    margin:0; padding:0;
                }
            }
        }
    }
}


