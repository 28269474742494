/* ================================================================
   Variables
   ================================================================ */

// Color Variables
$body-bg: #fff;
$color-base: #333;
$color-invert: #fff;
$color-primary: #002147;
$color-primary-dark: #001834;
$color-secondary: #8b734a;
$color-secondary-light: #AF9969;
$color-secondary-light2: #E9D7AF;
$color-secondary-lightest: #E6D1A7;
$color-secondary-logo: #D6C39B;
$color-gold: #F9F2E7;
$color-gray: #ECEDEE;
$color-gray2: #61686E;
$color-gray3: #666666;
$color-gray4: #CCCCCC;
$color-gray5: #DEDFE0;
$color-gold2: #f8eed6;
$color-light-blue: #A4AFBD;
$color-red: #851f1f;
$color-teal: #90A5AC;
$bg-location: #F1F1F1;
$color-yellow: #CD9F26;
$color-yellow-dark: #866719;
$bg-location-darker: #ECEDEE;

// Typography
$font-primary: 'Red Hat Text', sans-serif;
$font-secondary: 'Gelasio', serif;
$font-tertiary: 'Red Hat Display', sans-serif;
$font-heading: $font-secondary;

$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;
$fontWeight-superBold: 900;

$typography-margin-bottom: 1em;


// Breakpoints
// You can convert these at http://pxtoem.com
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 71.25em; // 1140px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 81.25em; // 1300px
$bp-xl-m: 84.375em; // 1350px
$bp-xxl: 104.375em; // 1670px

// Transitions
$trans-duration: 0.18s;
$trans-ease: ease-in-out;
$trans: all $trans-duration $trans-ease;

// Grid
$container-size: 87.5rem; // 1400px
$container-size-small: 64.375em; // 1000px + 30
$container-size-medium: 76.875em; // 1200px + 30
$container-size-large: 101.875em; // 1600px + 30
$grid-gutter: 15px;
$grid-gutter-em: 0.938em; // Changing grid gutter to em causes a compiling error :/
