.page-banner2 {
    height:400px;
    transform: translate(-52%, 0%);
    left: 52%;
    display:flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index:100;
}
.vidsss {
    width:100%;
    height: 720px;
    position: absolute;
    z-index: -99;
    left:0;
    height: 1000%;
}
.hider {
    width:100%;
    height: 1000%;
    position: absolute;
    z-index:1;
  
}

.video-banner-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index:-1;
}

.video-bg {
    width: 100%;
    height: 100%;
    position: absolute;
}

.video-bg {
    iframe {
        border: 0;
        position: absolute;
        top: -300px;
        right: 0;
        bottom: 0;
        left: 0;
    }
}


.video-banner-content {
    position: relative;
    z-index: 2;
    padding: 60px 0;
}
