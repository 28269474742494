.page-banner {
    position: relative;
    margin: 0;

    &.color-blue {
        .page-banner__inner {
           background: $color-primary;
        }
        .page-banner__heading {
            color:$color-invert;
        }
        .page-banner__sub-heading {
            color:$color-invert;
        }
    }
    &.color-gold {
        .page-banner__inner {
           background: $color-gold;
        }
        .page-banner__heading {
            color:$color-primary;
        }
        .page-banner__sub-heading {
            color:$color-base;
        }
    }
    &.color-white {
        .page-banner__inner {
           background: $color-invert;
        }
        .page-banner__heading {
            color:$color-primary;
        }
        .page-banner__sub-heading {
            color:$color-base;
        }
    }
    &.color-light-gray {
        .page-banner__inner {
           background: $color-gray;
        }
        .page-banner__heading {
            color:$color-primary;
        }
        .page-banner__sub-heading {
            color:$color-base;
        }
    }
    &.color-gray {
        .page-banner__inner {
           background: $color-gray2;
        }
        .page-banner__heading {
            color:$color-invert;
        }
        .page-banner__sub-heading {
            color:$color-invert;
        }
    }
    &.color-gray2 {
        .page-banner__inner {
           background: $color-gray2;
        }
        .page-banner__heading {
            color:$color-invert;
        }
        .page-banner__sub-heading {
            color:$color-invert;
        }
    }
    &.color-light-blue {
        .page-banner__inner {
           background: $color-light-blue;
        }
        .page-banner__heading {
            color:$color-primary;
        }
        .page-banner__sub-heading {
            color:$color-base;
        }
    }
    &.color-teal {
        .page-banner__inner {
           background: $color-teal;
        }
        .page-banner__heading {
            color:$color-primary;
        }
        .page-banner__sub-heading {
            color:$color-base;
        }
    }
}

.page-banner__image {
    padding-bottom:30%;
    background-size: cover;
    background-position: 100% 0;
}

.page-banner__inner {
    text-align: center;
    background:$color-primary;
    padding:15px;
    margin:0 -15px;
}

.page-banner__heading {
    @include x-rem(font-size, 30px);
    color:$color-invert;
    font-weight:bold;
    line-height:1.1;
    margin:0;
}
.page-banner__sub-heading {
    @include x-rem(font-size, 16px);
    color:$color-invert;
    font-weight:normal;
    margin: 10px 0;
    font-family: $font-tertiary;
}

.page-banner--alt {
    background:#f1f1f1;
    padding:20px 0;

    .page-banner__inner {
        background:none;
        padding:0;
        margin:0 auto;
        max-width:796px;
        border-radius: 0;
        text-align: center;
    }
    .page-banner__heading {
        color:$color-primary;
    }
    .page-banner__sub-heading {
        font-family: $font-primary;
        color:#666;
    }
}


@media (min-width: $bp-xl) {
    .page-banner {
        margin:0;
        padding:40px 0;
        display: flex;
        align-items: center; 

        &.page-banner--min {
            min-height:400px;
        }
    }
    .page-banner__image {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-size: cover;
        background-position: 100% 0;
        padding-bottom:0;
    }
    .page-banner__inner {
        position: relative;
        background:transparentize($color: $color-primary, $amount: .35);
        padding:40px;
        border-radius: 4px;
        max-width: 685px;
        text-align: left;
        margin:0;
    }

    .page-banner__heading {
        @include x-rem(font-size, 42px);
    }
    .page-banner__sub-heading {
        @include x-rem(font-size, 18px);
        margin:17px 0;
    }

    .page-banner--alt {
        padding:45px 0;
    }

    .page-banner {


        &.color-white {
            .page-banner__inner {
                background:transparentize($color: $color-invert, $amount: .35);
            }
        }
        &.color-light-gray {
            .page-banner__inner {
                background:transparentize($color: $color-gray, $amount: .35);
            }
        }
        &.color-gray {
            .page-banner__inner {
                background:transparentize($color: $color-gray2, $amount: .35);
            }
        }
        &.color-blue {
            .page-banner__inner {
                background:transparentize($color: $color-primary, $amount: .35);
            }
        }
        &.color-gold {
            .page-banner__inner {
               background:transparentize($color: $color-gold, $amount: .35);
            }
        }
        &.color-light-blue {
            .page-banner__inner {
               background:transparentize($color: $color-light-blue, $amount: .35);
            }
        }
        &.color-teal {
            .page-banner__inner {
               background:transparentize($color: $color-teal, $amount: .35);
            }
        }
        &.no-text-underlay {
            .page-banner__inner {
               background: none;
            }
        }
    }
}

@media (min-width: $bp-xl) {
    .page-banner__inner {
        padding:50px;
    }
    .page-banner__heading {
        @include x-rem(font-size, 50px);
    }
    .page-banner__sub-heading {
        @include x-rem(font-size, 20px);
        margin:17px 0;
    }
}

.page-banner__inner.page-banner__inner-right {
    margin-left: auto;
}

.page-banner__inner {
    z-index:99;
}

.page-banner__buttons-box {
    margin: 1rem auto;
    text-align: center;
}

.page-banner-text .page-banner__sub-heading, .page-banner-text .page-banner__heading {
    width:75%;
    text-align: center;
    margin: 15px auto;
}

.page-banner-texting {
    &.color-blue {

        background: $color-primary;

        .page-banner__heading {
            color:$color-invert;
        }
        .page-banner__sub-heading {
            color:$color-invert;
        }
    }
    &.color-gold {

        background: $color-gold;

        .page-banner__heading {
            color:$color-primary;
        }
        .page-banner__sub-heading {
            color:$color-base;
        }
    }
    &.color-white {

        background: $color-invert;

        .page-banner__heading {
            color:$color-primary;
        }
        .page-banner__sub-heading {
            color:$color-base;
        }
    }
    &.color-light-gray {

        background: $color-gray;

        .page-banner__heading {
            color:$color-primary;
        }
        .page-banner__sub-heading {
            color:$color-base;
        }
    }
    &.color-gray {

        background: $color-gray2;

        .page-banner__heading {
            color:$color-invert;
        }
        .page-banner__sub-heading {
            color:$color-invert;
        }
    }
    &.color-light-blue {

        background: $color-light-blue;

        .page-banner__heading {
            color:$color-primary;
        }
        .page-banner__sub-heading {
            color:$color-base;
        }
    }
    &.color-teal {

        background: $color-teal;

        .page-banner__heading {
            color:$color-primary;
        }
        .page-banner__sub-heading {
            color:$color-base;
        }
    }
    &.no-text-underlay {
        background: none;
    } 
}