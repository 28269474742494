.quicklinks {
    box-shadow:0px 3px 6px -2px #333;
    margin:30px 0;
    background:#f1f1f1;
    padding:25px 20px;
    border-radius: 4px;
}
.quicklinks__heading {
    margin:0;
    border-bottom:3px solid $color-secondary;
    @include x-rem(font-size, 20px);
    padding:0 0 12px;
}
.quicklinks-list {
    @include resetList;
}
.quicklinks-list__item {
    border-bottom:1px solid #999;
    break-inside: avoid-column;
    page-break-inside: avoid;
}
.quicklinks-list__link {
    display: flex;
    align-items: center;
    padding:15px 0;
    font-family: $font-tertiary;
    font-weight: bold;
    text-decoration: none;

    &:after {
        content:'';
        background-image:url(../images/right-arrow.svg);
        width: 8px;
        height: 12px;
        margin: 0 0 0 7px;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
.quicklinks-list__icon {
    width: 16px;
    height: 16px;
    fill: currentColor;
    display: inline-block;
    position: relative;
    top: 2px;
}

@media (min-width: $bp-mw) {
    .quicklinks {
        margin: 50px 0;
        padding: 40px;
    }
    .quicklinks__heading {
        @include x-rem(font-size, 24px);
    }
    .quicklinks-list {
        column-count: 2;
        column-gap: 25px;
    }
}

.interior-rail .quicklinks-list {
    column-count: 1;
}