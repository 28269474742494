.search-ribbon {
    background: $color-primary;
    text-align: left;
    margin:0 0 30px;
    padding: 35px 0;
}

.search-ribbon__container {
    display: block;
    align-items: center;
}

.search-ribbon__heading {
    color: $color-invert;
    margin: 0;
    @include x-rem(font-size, 20px);
}

.search-ribbon__icon {
    width: 30px;
    height: 30px;
    fill: $color-secondary-light;
    margin-right: 20px;
}

.search-ribbon__description-area {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.search-ribbon__selection {
    margin: auto;
    max-width: 30rem;
}

.search-ribbon__description {
    color: $color-invert;
    font-weight: 500;
    margin:0 0 5px;
    display:block;
}

.search-ribbon__select {
    height: 46px;
    border-radius: 4px;
    border: 1px solid $color-gray;
    display: flex;
    margin: 0 auto;
    height: 46px;
    border-radius: 4px;
    color: $color-base;
    font-family: $font-primary;
    letter-spacing: 0;
    line-height: 20px;
    appearance: none;    
    width: 100%;
}

.button-go {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin: 20px auto;
    max-width: 240px;
    width: 100%;
}

.search-ribbon__wrapper {
    position: relative;
}

.search-icon-chevron-down {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 20px;
    max-height: 20px;
    pointer-events: none;
}

@media (min-width: $bp-mw) {
    .search-ribbon {        
        padding: 30px 0;
        text-align: left;
    }

    .search-ribbon__description {
        padding: 0 20px;
        margin: 0;
        max-width: 180px;
        width: 100%;
    }

    .search-ribbon__container {
        display: flex;
        align-items: center;
    }

    .search-ribbon__container {
        &:before,
        &:after {
            display:none;
        }
    }

    .search-ribbon__heading {
        color: $color-invert;        
        white-space: nowrap;        
        margin: 0;
    }

    .search-ribbon__selection {        
        display: flex; 
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        width: 64%;    
        max-width: 100%;
    }

    .search-ribbon__wrapper{
        width:100%;
    }

    .search-ribbon__select{
        width: 100%;
    }

    .search-ribbon__description-area {
        margin-bottom: 0;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
        padding:15px 5% 15px 0;
        margin:0 4% 0 0;
    }    

    .search-ribbon__button-container {
        margin: 0 0 0 auto;
        width: auto;
        padding: 0;
        border: 0;
    }

    .button-go {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        padding: 13px 50px 13px 50px;
        margin:0 0 0 5px;
    }
}

@media (min-width: $bp-l) {
    .search-ribbon__heading {   
        @include x-rem(font-size, 28px);
    }
    .search-ribbon__description {        
        @include x-rem(font-size, 20px);
    }
}