/* ================================================================
   News - Majority of styling is done as part of the lists.scss
   ================================================================ */

.news {
    
}

.news-details__back {
    font-family: $font-tertiary;
    text-decoration: none;
    display:flex;
    font-weight: bold;
    @include x-rem(font-size, 16px);
    margin:15px 0 0 0; 

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
.news-details__back-icon {
    width:22px;
    height:22px;
    margin:0 5px 0 0;
    fill:currentColor;
}
.news-details__category {
    font-weight: bold;
    @include x-rem(font-size, 16px);
    margin: 0 0 3px;
    font-family: $font-primary;
}
.news-details__category--large {
    @include x-rem(font-size, 20px);
}
.news-details__date {
    margin: 0 0 18px;
    color: #434343;
    line-height: 1;
    @include x-rem(font-size, 16px);
    font-family: $font-primary;
}
