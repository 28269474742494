.board-listing {
    padding: 0;
}
.board-listing-item__title {
    font-size: 18px;
    padding: 15px;
    padding-bottom: 0;
}

.board-item {
    list-style-type: none;
}

.listing-container{
    display: block;
}

.listing-left {
    width: 100%;
    padding-right: 20px;
}

.listing-right {
    display: flex;
    width: 100%;
}

.board-listing-item__position {
    padding-right: 30px;
}

.listing-right-position {
    width: 50%;
    padding: 0 15px 0 15px;
}

.listing-right-company {
    width: 50%;
    padding: 0 15px 0 15px;
}

.board-item:nth-child(2n){
    background-color:$color-gray;
}
@media (min-width: $bp-m) { 
    .listing-container{
        display: flex;
    }

    .listing-left {
        width: 50%;
    }

    .listing-right {
        width: 50%;
    }

    .listing-right-position {
        width: 50%;
        padding: 15px;
    }
    
    .listing-right-company {
        width: 50%;
        padding: 15px;
    }
}