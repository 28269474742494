﻿/******************************** 
** News Carousel 
********************************/
.news-carousel {
    position: relative;
    padding: 40px 0;

    @include breakpoint($bp-mw) {
        padding: 60px 50px;
    }

    h2 {
        text-align: center;
        margin-bottom: 40px;
    }

    .a11y-slider-container {
        overflow: hidden;
    }
}

.news-carousel__slider {
    display: flex;
    margin: .5em -20px;

    & > * {
        width: 100%;
        flex: 0 0 auto;
        padding: 0 20px;
    }

    @media (min-width: 768px) {
        & > * {
            width: 50%;
        }
    }

    @media (min-width: 960px) {
        & > * {
            width: 33.3333%;
        }
    }

    @media (min-width: 1280px) {
        & > * {
            width: 25%;
        }
    }
}

.news-carousel-arrow {
    @extend %carousel-btn;

    &--prev {
        left: -15px;
    }

    &--next {
        right: -15px;
    }
}

.news-carousel__nav {
    display: flex;
    gap: 50px;
    justify-content: center;
    margin: 20px 0;

    .news-carousel-arrow {
        position: static;
        transform: none;

        @include breakpoint($bp-mw) {
            position: absolute;
            transform: translateY(-50%);
        }
    }
}

.news-carousel__container {
    height: 100%;
}

.news-carousel__inner {
    height: inherit;
    display: flex;
    flex-direction: column;
}

.news-carousel__figure {
}

.news-carousel__img {
    display: block;
    background: no-repeat center center / cover;
    aspect-ratio: 322/250;

    &::after {
        content: '';
        display: block;
    }
}

.news-carousel-info-box {
    background-color: $color-gray;
    padding: 30px;
    height: 100%;
}

.news-carousel-info-box__cat {
    font-size: pxtorem(14px);
    font-weight: $fontWeight-bold;
    color: $color-yellow-dark;
    width: fit-content;
    margin-bottom: 20px;
}

.news-carousel-info-box__title {
    font-size: pxtorem(20px);
    margin-bottom: 10px;
}

.news-carousel-info-box__description {
    font-size: pxtorem(18px);
    margin-bottom: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /*autoprefixer: off */
    -webkit-box-orient: vertical;
    /*autoprefixer: on */
}

.news-carousel-info-box__button {
    font-size: pxtorem(16px);
    background: transparent;
    padding: 0;
    display: inline-flex;
    align-items: center;

    &:hover,
    &:active,
    &:focus {
        background: transparent;
        text-decoration: underline;

        svg {
            transform: translateX(2px);
        }
    }

    svg {
        width: 18px;
        height: 18px;
        fill: currentColor;
        transition: $trans;
        transform: translatex(0);
    }
}
