/* ================================================================

Pagination

   ================================================================ */

   .pagination-main {
      margin:0 0 30px;
   }
   
   .pagination-main__results {
      font-weight: bold;
      margin:0 0 15px;
   }
   
   .pagination-main__list {
      list-style: none;
      padding:0;
      margin:0 0 0 auto;
      display:flex;
   }
   
   .pagination-main__list-item {
      margin:0 2.5px;
   }
   
   .pagination-main__link {
      padding:3px 6px;
      display:block;
      height:36px;
      width:36px;
      border:1px solid #ccc;
      text-align:center;
      position:relative;
      color:$color-primary;
      @include x-rem(font-size, 18px);
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
   
      &:hover,
      &:focus {
         @extend .pagination-main__link--active;
      }
   }
   
   .pagination-main__link--active {
      background: $color-primary;
      color:$color-invert;
      border:0;
   }

   .pagination-main__list-item--prev,
   .pagination-main__list-item--next {
      width:24px;
      background:none;
      border:0;

      svg {
         width:20px;
         height:20px;
         position:absolute;
         left:50%;
         top:50%;
         transform: translateY(-50%) translateX(-50%);
         fill:$color-primary;
      }

      &:hover,
      &:focus {
         svg {
            fill:$color-invert;
         }
      }
   }

   @media (min-width: $bp-mw) {
      .pagination-main {
         display:flex;
         justify-content: space-between;
         align-items: center;
         margin:0 0 30px;
      }
      .pagination-main__results {
         @include x-rem(font-size, 20px);
         margin:0;
      }

      .pagination-main__link {
         height:46px;
         width:46px;
         @include x-rem(font-size, 20px);
      }
   
      .pagination-main__list-item--prev,
      .pagination-main__list-item--next {
         width:30px;
      }
   }
   
   .js-pagination {
      display:flex;
      align-items: center;
      justify-content: flex-end;
      padding:10px 0 0 0;
      border-top:1px solid #999999;
   
      a {
          padding:3px 5px;
          display:block;
          height:30px;
          width:22px;
          text-align:Center;
          position:relative;
          color:$color-base;
      }
   }
   .js-pagination-inner {
      display:flex;
      align-items: center;
   }
   .js-pagination__previous,
   .js-pagination__next {
      padding:3px 6px;
      display:block;
      height:30px;
      width:24px;
      text-align:Center;
      position:relative;
      color:$color-base;
      border:0;
      background:none;
      cursor:pointer;
   
      svg {
          width:20px;
          height:20px;
          left:50%;
          top:50%;
          position: absolute;;
          transform: translateY(-50%) translateX(-50%);
          fill:$color-secondary;
       }
      &.jp-disabled {
          display:none;
      }
   }
   