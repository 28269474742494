/* ================================================================
   Lists
   ================================================================ */

.listing-section {
    margin: 0 0 30px;
    border-radius: 10px;
}

.listing-header {
    @include x-rem(font-size, 30px);
    font-weight: normal;
    font-family: $font-secondary;
    line-height: 1;
    margin: 0 0 25px;
    padding: 0 0 25px;
    border-bottom: 1px solid #e1e1e1;
}

.listing {
    margin: 0;
    padding: 0;
    list-style: none;
}

.listing-item {
    padding: 0 0 25px;
    margin: 0 0 25px;
    border-bottom: 1px solid #e1e1e1;

    &:first-child {
        border-top: 1px solid #e1e1e1;
        padding-top:25px;
    }
}

.li-meta {
    display: block;
    font-weight: bold;
    @include x-rem(font-size, 14px);
    margin: 0 0 3px;
}

.li-img {
    margin: 0 0 20px;
    flex: 0 0 160px;
}

.li-date {
    margin: 10px 0 12px;
    color: #434343;
    line-height: 1;
    @include x-rem(font-size, 14px);
}

.li-date-head {
    display: none;
    @include x-rem(font-size, 31px);
    margin: 0 0 10px;
}

.li-date-bot {
    display: none;
    @include x-rem(font-size, 12px);
    text-transform: uppercase;
}

.li-date-mb {
    @include x-rem(font-size, 14px);
    text-transform: uppercase;
}

.li-content {
    flex: 1 1 auto;
}

.li-title {
    color: $color-primary;
    @include x-rem(font-size, 20px);
    font-family: $font-primary;
    font-weight: bold;
    margin: 0 0 5px;
    line-height: normal;
}

.li-title__link {
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.li-extra {
    display:flex;
    flex-wrap: wrap;
    margin:-10px -10px 10px;
    @include x-rem(font-size, 14px);
    font-style: italic;
}
.li-extra__item {
    margin:0 10px;
}

.li-desc {
    margin: 0 0 5px;
    @include x-rem(font-size, 20px);

    &:last-child {
        margin:0;
    }
}

.li-more {
    text-decoration: underline;
    font-weight: 500;
    display:inline-flex;
    align-items: center;
    margin:15px 0 0 0;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.listing-more {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include x-rem(font-size, 18px);
    font-family: $font-secondary;
    line-height: normal;

    svg {
        width: 12px;
        height: 12px;
        fill: currentColor;
        margin: 0 0 1px 7px;
    }
}

.listing-tags {
    @include resetList;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0 0 0;
}

.listing-tags__item {
    display: block;
    margin: 0 5px 5px 0;
}

.listing-tags__text {
    @include x-rem(font-size, 12px);
    background: $color-primary;
    color: $color-invert;
    padding: 3px 5px;

    @at-root a#{&} {
        &:hover,
        &:focus {
            background: transparentize($color: $color-primary, $amount: 0.2);
        }
    }
}

@media (min-width: $bp-mw) {
    .listing-intro-inner {
        flex-direction: row;
        font-weight: normal;

        svg {
            margin: 0 45px 0 0;
            width: 81px;
            height: 77px;
        }

        span {
            max-width: 140px;
        }
    }

    .listing-item {
        display: flex;
        justify-content: flex-start;
    }

    .li-date-head {
        display: block;
    }

    .li-date-bot {
        display: block;
    }

    .li-date-mb {
        display: none;
    }

    .li-img {
        margin: 0 10px 0 0;
    }

    .li-desc {
        margin: 0 0 15px;
    }
}

@media (min-width: $bp-l) {
    .listing-section-inner {
        padding: 30px;
    }

    .listing-item {
        padding: 0 10px 25px;

        @at-root .extra-pad & {
            padding: 0 20px 25px;

            &:first-child {
                padding-top:25px;
            }
        }
    }

    .li-img {
        margin: 0 25px 0 0;
    }
}

/* Link List */

.link-list {
    @include resetList;

    li {
        margin:0 0 12px;
    }

    a {
        display:inline-flex;
        align-items: center;
        font-family: $font-tertiary;
        font-weight:bold;
        text-decoration: none;

        &:after {
            content:'';
            background-image:url(../images/right-arrow.svg);
            width:8px;
            height:12px;
            margin:0 0 0 5px;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

}
@media (min-width: $bp-mw) {
    .link-list {
       a {
        @include x-rem(font-size, 20px);
       }
    }
}


/* list with checkboxes */

.check-list {
    @include resetList;
    overflow: hidden;

    li {
        border-top:1px solid #999;
        padding:10px 0 10px 40px;
        position: relative;
        color:$color-primary;
        font-family: $font-tertiary;
        font-weight:bold;

        &:before {
            content:'';
            position: absolute;
            left:0;
            top:10px;
            width:24px;
            height:24px;
            background:$color-secondary;
            border-radius: 50%;
        }
        &:after {
            content:'';
            position: absolute;
            left: 9px;
            top: 15px;
            width: 6px;
            height: 12px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }

        &:first-child {
            border:0;
        }
    }

}
@media (min-width: $bp-mw) {
    .check-list {
        display:flex;
        flex-wrap: wrap;
        margin:0 -30px;

        li {
            width:calc(50% - 60px);
            margin:0 30px;
            @include x-rem(font-size, 20px);
            padding:35px 0 35px 40px;

            &:before {
                top:35px;
            }
            &:after {
                top:40px;
            }

            &:nth-child(2) {
                border:0;
            }
        }
    }
}

.twoColumns {
    column-count: 2;
}
