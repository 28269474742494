/* ================================================================
   Button Styles
   ================================================================ */

.button,
.btn {
	display:inline-block;
	padding:12px 24px;
	border:0;
	font-family:$font-tertiary;
	font-weight:bold;
	color:$color-primary;
	text-decoration:none; text-align:center;
	background: $color-secondary;
	background: linear-gradient(180deg, #DAC188 0%, #E6D1A7 52%, $color-secondary 100%);
	cursor: pointer;
    transition: $trans;
	line-height:1.4;
	border-radius:4px;

	&:hover, &:focus {
		text-decoration:none;
		background:$color-secondary-lightest;
        color:$color-primary;
	}	

	.button-with-icon {
		display:flex;
		align-items: center;
		justify-content: center;

		svg {
			fill:currentColor;
			width:18px;
			height:18px;
			margin-left:6px;
		}
	}
}

.button-ghost {
	display:inline-block;
	padding:11px 23px;
	border:1px solid $color-primary;
	font-family:$font-tertiary;
	font-weight:bold;
	color:$color-primary;
	text-decoration:none; text-align:center;
	background: transparent;
	cursor: pointer;
    transition: $trans;
	line-height:1.4;
	border-radius:4px;

	&:hover, &:focus {
		text-decoration:none;
		background:$color-primary;
		color:$color-invert;
	}
}
.button-2 {
	display:inline-block;
	padding:11px 23px;
	border:1px solid $color-primary;
	font-family:$font-tertiary;
	font-weight:bold;
	color:$color-invert;
	text-decoration:none; text-align:center;
	background: $color-secondary;
	background: $color-primary;
	cursor: pointer;
    transition: $trans;
	line-height:1.4;
	border-radius:4px;

	&:hover, &:focus {
		text-decoration:none;
		background:$color-invert;
		color:$color-primary;
	}	
}

.back {
	@extend .button-small !optional;
	text-transform: capitalize;
}

.more {
	@extend .button-small !optional;
	text-transform:capitalize;
}

%carousel-btn {
    @include resetBtn;
	position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    font-size: 0;
    color: $color-primary;
    transition: $trans;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    svg {
        display: inline-block;
        width: 100%;
        height: 100%;
        fill: currentColor;
        transition: $trans;
    }

    &:hover,
    &:focus {
        color: $color-secondary;
	}

	@media (min-width: $bp-mw) {
		width: 60px;
		height: 60px;
	}
}