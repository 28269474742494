/* ================================================================
   Filters
   ================================================================ */

.filters {
    margin:0 0 1rem;
}

.filter {
    margin:0 0 15px;
    position: relative;

    .button {
        margin:0 5px 5px 0;
    }
}

.filter__label {
    display:block;
    margin:0 0 3px;
    white-space: nowrap;
    color:#333;
    font-family: $font-tertiary;
    font-weight: 600;
}
.filter__label--date {
    font-size:.875em;
    padding:0 5px 0 0;
}

.filter__select {
    width:100%;
    border-radius: 4px;
}

.filter__input {
    width:100%;
    border-radius: 4px;
}
.filter__input--date {
    width:70px;
}

.filter__fieldset {
    margin:0 0 15px;
}

.filter__legend {
    display:block;
    margin:0 0 3px;
    width:100%;
}

.filter__fieldset-content {
    display:flex;
}

.filter__inner-field {
    padding:0 10px 0 0;
}

.filter__search-content {
    position:relative;
}

.filter__search-button {
    position:absolute;
    height:46px;
    right:0px;
    bottom:0;

    &:hover,
    &:focus {
        color: $color-secondary;
    }
}

.filter--nolabel {
    &::before {
        @extend .filter__label;
        content: '\a0'; // non breaking space
    }
}

@media (min-width: $bp-l) {
    .filters {
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin:0 -10px;
    }

    .filters--stacked {
        display: block;

        .filter {
            margin-bottom: 10px;
        }
    }

    .filter {
        padding:0 10px;
        width:100%;
        margin:0;
        display: flex;
        align-items: center;
        width: 50%;
        margin:0 0 35px;

        &.filter--full {
            width: 100%;
        }

        &.filter--33 {
            width:33.33%;
        }
    }

    .filter__label {
        margin:0;
        padding:0 7px 0 0;
    }

    .filter--date {
        display:flex;
    }

    .filter__fieldset {
        margin:0;
    }

    .filter__fieldset-content {
        justify-content: space-between;
    }

    .filter__inner-field {
        display:flex;
        align-items:center;
        padding:0 10px 0 0;
    }
    .filter__search-button {
        right:10px;
    }
}