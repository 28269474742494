.site-container #be-ix-link-block {
    background:#ECEDEE;
    border-top:1px solid #CCCCCC;
    padding:15px 0;

    .be-related-link-container {
        padding:0;
        text-transform: none;
        font-family:$font-primary;
        align-items:center;

        .be-label {
            color:$color-primary;
            @include x-rem(font-size, 20px);
            font-weight:bold;
            flex-basis: auto;
        }
        .be-list {
            
        }
        .be-list-item { 
            margin:5px;
        }
        .be-related-link {
            font-weight:bold;
            color:$color-primary;
        }
    }
  
}