.interior-top {
    margin: 0 0 30px;

    .page-extra-container  {
        order:5;
    }

    &.interior-top--no-space {
        margin:0;
    }
    &.interior-top--no-heading {
        margin:0 0 30px;
    }
}
.interior-main {
    padding:0 0 30px;
}

.interior-rail {
    padding:30px 0 0 0;
}

.page-extra-container {
    display: none;
}

.page-extra-col {
    text-align: center;
    @include x-rem(font-size, 11px);
}

@media (min-width: $bp-mw) {
    .interior-top {
        margin:0 0 40px;

        &.interior-top--no-space {
            margin:0;
        }
        &.interior-top--no-heading {
            margin:0 0 40px;
        }
    }
    .interior-main {
        padding:0 0 60px;
    }

    .interior-rail {
        padding:0 0 0 30px;
    }

    .page-extra-container {
        display: block;
        margin-top:35px;
    }

    .page-extra-col {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include x-rem(font-size, 16px);
    }
}