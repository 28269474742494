
.expandable-examples {
  *:focus {
      outline: 1px solid blue;
  }

  div {
      margin: 1em 0;
  }
}

.color-block {
  display:inline-block;
  margin:5px;
  text-align:center;
  width:120px; height:120px;
  border-radius:240px;

  &.color-primary { background:$color-primary; }
  &.color-secondary { background:$color-secondary; }
}

#svg-list {
  font-size: 0;

  .item {
    display: inline-block;
    width: 150px;
    vertical-align: top;
    padding: 10px;
    text-align: center;

    .title {
      font-weight: bold;
      padding: 5px;
      font-size: 16px;
    }

    .ident {
      padding: 5px;
      font-size: 14px;
    }

    svg {
      display: inline-block;
      width: 100%;
    }
  }
}

.container .talon-templates-wrapper {
  position: static;
  width: auto;
  background: none;
  border: none;
  padding: 0 0 1em 1em;

  .talon-templates-hide {
    display: none;
  }

  &::before { content: none; }
}

.styleguide-tabs { display: flex; }
.styleguide-tab {
  display: none;
  &.active {
    border-color: red;
  }
}

@media (min-width: 768px) {
    .styleguide-tab { display: block; }
    .styleguide-mob-tab { display: none; }
}