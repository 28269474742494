.compare-tool {
    @include x-rem(font-size, 16px);
}
.compare-tool__top {
    
}
.compare-tool__top-heading {
    
}
.compare-tool__top-buttons {

}
.compare-tool-button-list {
    @include resetList;
    margin:0 -10px;
}
.compare-tool-button-list__item {
    padding:0 10px;
    margin:0 0 15px;
}
.compare-tool-button {
    display:block;
    width:100%;
    background:none;
    border:1px solid transparent;
    text-align:left;
    font-family: $font-tertiary;
    font-weight:bold;
    color:$color-primary;
    padding:13px 20px 13px 48px;
    position: relative;
    cursor:pointer;

    .compare-tool-button__checkbox {
        border:1px solid #cccccc;
        background: #fff;
        width:21px;
        height:21px;
        position: absolute;
        top:11px;
        left:14px;
        font-size:0;

        &:before {
            content:'';
            position: absolute;
            left: 7px;
            top: 2px;
            width: 6px;
            height: 12px;
            border: solid #f1f1f1;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            display:none;
        }
    }

    &.active {
        background:#f1f1f1;
        border:1px solid #cccccc;
        border-radius:4px;
        box-shadow:0px 3px 4px -2px #999;

        .compare-tool-button__checkbox {
            background:$color-primary;
            border:1px solid $color-primary;

            &:before {
                display:block;
            }
        }
    }

    &:hover,
    &:focus {
        background:#f1f1f1;
        border:1px solid #cccccc;
        border-radius:4px;
        box-shadow:0px 3px 4px -2px #999;

        &.active {
            background:#ddd;
        }
    }
}


.compare-tool-table {
    width:100%;
    border-collapse: collapse;
    display:none;
    border-bottom:1px solid #999;

    tr {
        
        &:nth-child(2) th:first-child {
            margin-top:30px;
        }
    }
    td {
        border-left:1px solid #999;
        border-right:1px solid #999;
        padding:8px 14px;
        display:block;

        &:last-child {
            border-bottom:1px solid #999;
        }

        &[data-product*="account"] {
            display:none;
        }
        &.active {
            display:block;
        }
    }
    .compare-tool-table__product-name {
        @include x-rem(font-size, 16px);
        font-family: $font-tertiary;
        color:#333;
        margin:0;
    }
}
.compare-tool-table__th-main {
    background:$color-primary;
    color:$color-invert;
    padding:15px 20px;
    vertical-align: top;
    font-weight: normal;
    text-align: left;
    display:block;

    &:first-child {
        padding:20px 20px 15px 20px;
    }
    &:last-child {
        padding:15px 20px 20px 20px;
    }

    .compare-tool-table__image {
        max-width:180px;
        margin:0 0 15px;
    }

    h2 {
        @include x-rem(font-size, 20px);
        font-family: $font-tertiary;
        color:$color-invert;
        margin:0 0 5px;
    }
    p {
        @include x-rem(font-size, 16px);
    }
    .compare-tool-table__buttons {

    }
    .button {
        width:100%;
        max-width:175px;
        margin:7px 0 0 0;
    }

    &[data-product*="account"] {
        display:none;
    }
    &.active {
        display:block;
    }
}
.compare-tool-table__th-sub {
    border:1px solid #999;
    padding:8px 14px;
    text-align: left;
    display:block;
    background:#f1f1f1;
    margin:-1px 0 0 0;

    h2 {
        @include x-rem(font-size, 16px);
        font-family: $font-tertiary;
        margin:0;
    }
}


@media (min-width: $bp-m) {
    .compare-tool {
        
    }
    .compare-tool__top {
        display:flex;
        margin:0 0 50px;
    }
    .compare-tool__top-heading {
        width:35%;
        padding:0 30px 0 0;
        @include x-rem(font-size, 28px);
    }
    .compare-tool__top-buttons {
        width:65%;
    }
    .compare-tool-button-list {
        display:flex;
        flex-wrap: wrap;
    }
    .compare-tool-button-list__item {
        width:50%;
    }
    .compare-tool-button {

    }

    .compare-tool-table-container {
        overflow-x: auto;
    }
    .compare-tool-table {
        border-collapse: separate;
        border-spacing: 0;
        border:0;
        height:100%;
        table-layout:fixed;

        tr {
   
        }
        td {
            border:0;
            border-bottom:1px solid #999;
            padding:35px 20px;
            display:table-cell;
            background:#fff;

            &.active {
                display:table-cell;
            }
        }
        .compare-tool-table__product-name {
            display:none;
        }
    }
    .compare-tool-table__th-main {
        padding:40px 20px 40px;
        vertical-align: top;
        position: relative;
        display:table-cell;
        width:274px;
        height:100%;
    
        &:first-child {
            padding:40px 20px 40px 40px;
            position: sticky;
            left:0;
            z-index:5;
            width:274px;
        }
        &:last-child {
            padding:40px 40px 40px 20px;

        }

        .compare-tool-table__th-main-inner {
            height:100%;
            display:flex;
            flex-flow: column;
        }

        h2 {

        }
        p {
  
        }
        .compare-tool-table__buttons {
            margin:auto 0 0 0;
        }

        &.active {
            display:table-cell;
        }
    }
    .compare-tool-table__th-sub {
        border:0;
        border-bottom:1px solid #999;
        background:#fff;
        padding:35px 20px 35px 40px;
        display:table-cell;
        margin:0;
        position: sticky;
        left:0;
        z-index:5;
    
        h2 {
            @include x-rem(font-size, 20px);
            margin: 0;
        }
    }
}

@media (min-width: $bp-l) {
    .compare-tool__top-heading {
        width:30%;
        @include x-rem(font-size, 32px);
    }
    .compare-tool__top-buttons {
        width:70%;
    }
    .compare-tool-button-list__item {
        width:33.33%;
    }

    .compare-tool-table__th-main {
        width:auto; 
    }
}