.directors-table {
    width: 100%;
    margin:0 0 20px;

    a {
        display:inline-block;
        margin:2px 0;
    }
}
.directors-table__titles {
    background-color: #fff;
    font-family: $font-secondary;
    text-align: left;
    @include x-rem(font-size, 20px);
}

.directors-table tr:nth-child(even) {
    background-color: $color-gray;
}

.directors-table__titles-mobile {
    display: table-row;
    border-bottom:2px solid $color-primary;
    font-size: 16px;
    font-weight: 900;
    color: $color-primary;
    background-color: $color-secondary-lightest;
}
.directors-table td {
    padding: 7px 10px;
    @include x-rem(font-size, 18px);
    vertical-align: top;

    h2 {
        @include x-rem(font-size, 20px);
        margin:0 0 3px;
    }
}
@media (min-width:769px) {
    .directors-table__titles-mobile{
        display: none;
    }
}

.directors-table__titles {
    display: none;
}

@media (min-width:769px) {
    .directors-table__titles{
        display: table-row;
    }
    .directors-table__titles th {
        padding: 15px 10px;
    }
}

@media (max-width: 767px) {
    .directors-table__oneitem {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
        border: 1px solid $color-primary;
        margin:0 0 -1px;
    }
    
    .directors-table td {
        padding: 10px;
    }
}



@media(min-width:$bp-sw-max){
    .on-mobile { 
        display: none;
    }
}
