/*================================================================
            CLICK MENU OVERWRITE RAIL NAVIGATION STYLES
================================================================*/


.rail-nav {
    border:1px solid #ccc;
    border-radius: 4px;
    box-shadow:0px 3px 6px -2px #333;
    padding:15px;
    margin:0 0 30px;
    
    .rail-nav__heading {
        @include x-rem(font-size, 20px);
        margin:0;
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
    .rail-nav-expander {
        border:0;
        padding:0;
        background:none;
        width:30px;
        height:30px;
        position: relative;
        cursor: pointer;

        &:before {
            content:'';
            width:14px;
            height:2px;
            background:$color-primary;
            position: absolute;
            top:50%;
            left:50%;
            transform: translateX(-50%) translateY(-50%);
        }
        &:after {
            content:'';
            width:2px;
            height:14px;
            background:$color-primary;
            position: absolute;
            top:50%;
            left:50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    a {
        background: none;
        color: $color-primary;
        font-family: $font-tertiary;
        display:block;
        text-decoration: none;

        &:hover,
        &:focus {
            background: none;
            text-decoration: none;
            color: currentColor;
        }
    }

    > ul {
        @include resetList;
        margin:15px 0 0 0;
        background: none;
        border-top:3px solid $color-secondary;

        ul {
            display:none;

            &.active {
                display:block;
            }
        }
        li.active > ul,
        li.container-active > ul {
            display:block;
        }

        // 1st level
        > li {
            border-bottom:1px solid #999;

            > a {
                color: $color-primary;
                padding:16px 24px;
                font-weight:bold;

                &:hover,
                &:focus {
                    background:$color-primary;
                    color:$color-invert;
                }
            }

            &.active,
            &.container-active {
                border:0;
                background:#ECEDEE;
                border-radius: 0 0 4px 4px;
                margin:-1px 0 0 0;

                > a {
                    font-weight:bold;
                }
            }

            // 2nd level
            > ul {
                @include resetList;
                padding:0 24px 15px;

                 li {

                    a {
                        padding: 3px 15px;
                        position: relative;
                        
                        &:hover,
                        &:focus {
                            text-decoration: underline;
                        }
                    }

                    &.active {
                        > a {
                            font-weight:bold;

                            &:before {
                                content:'';
                                width:2px;
                                height:8px;
                                background:currentColor;
                                position: absolute;
                                left:4px;
                                top:12px;
                                transform: rotate(45deg);
                            }
                            &:after {
                                content:'';
                                width:2px;
                                height:8px;
                                background:currentColor;
                                position: absolute;
                                left:4px;
                                top:7px;
                                transform: rotate(-45deg);
                            }
                        }
                    }

                    ul {
                        @include resetList;
                        padding:0 0 0 15px;
                    }
                }
                
            }
        }
    }

    // Tablet/Desktop Styles
    @media (min-width: $bp-mw) {
        padding:24px 28px;
        margin:0 0 50px;

        .rail-nav__heading {
            @include x-rem(font-size, 24px);
        }

        .rail-nav-expander {
            display:none;
        }

        > ul {
            display:block !important;
        }
    }

    @media (min-width: $bp-l) {
        padding:32px 38px;
    }
}