.page-banner--location {
    background:#F1F1F1;
    padding:20px 0;
    margin:0;

    .page-banner__image {
        display:none;
    }

    .page-banner__inner {
        background:none;
        max-width:630px;
        margin:0 auto;
        padding:0;
    }
    .page-banner__heading {
        color:$color-primary;
        text-align: center;
    }
    .page-banner__sub-heading {
        color:#333;
        text-align: center;
    }
    .page-banner-form {
        padding:20px 0 0 0;
        max-width:400px;
        margin:0 auto;
    }
    .page-banner-form__fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin:0 0 20px;

        .custom-checkbox {
        }
    }
    .page-banner-form__input-container {
        width:100%;
        position: relative;
        margin:0 0 15px;
    }
    .page-banner-form__input {
        width:100%;
        border:1px solid #ccc;
        height:46px;
        border-radius: 4px;
        padding-right:44px;
    }
    .use-my-location {
        position: absolute;
        top:0;
        right:0;
        height:100%;
        width:50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color:$color-primary;
        background:transparent;
        border:0;
        cursor:pointer;

        svg {
            width:20px;
            height:20px;
            fill:currentColor;
        }

        &:hover,
        &:focus {
            color:#fff;
            background:$color-primary;
        }
    }
    .page-banner-form__fieldset {
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .page-banner-form__fieldset-legend {
        display:block;
        width:100%;
        margin:0 0 10px;
        color:$color-primary;
        font-weight:bold;
        text-align: left;
    }
    .page-banner-form__select {
        border:1px solid #ccc;
        height:46px;
        border-radius: 4px;
        width:100%;
    }
    .page-banner-form__button {
        width:100%;
    }
}

@media (min-width: $bp-m) {
    .page-banner--location {
        padding:35px 0;
        margin:0 0 35px;

        .page-banner__image {
            background-position: 50% 50%;
            position: absolute;
            display:block;
    
            &:after {
                content:'';
                -webkit-backdrop-filter: blur(6px);
                backdrop-filter: blur(6px);
                position: absolute;
                width:100%;
                height:100%;
                left:0;
                top:0;
                background:rgba(255,255,255,.5);
            }
        }

        .page-banner-form {
            padding:15px 0 0 0;
            display:flex;
            align-items: flex-start;
            max-width:none;
        }
        .page-banner-form__fields {
            padding:0 35px 0 0;
            margin:0;
        }
        .page-banner-form__select {
            width:auto;
            margin:0;
        }
        .page-banner-form__button {
            width:auto;
        }
    }
}

#js-dealers-error,
#js-dealers-radius-error {
    border-bottom:1px solid #ddd;
    padding:0 0 20px;
    margin:0 0 20px;
    color:#CD0404;
}
#js-dealers-error {
    border:0;
    padding:0;
}

.location-listing-container__map {
    /* temp style */
    background:#eee;
    margin:0 0 30px;
    order:2;
}

.google-map-pin {
    color:#fff !important;
    font-family: $font-tertiary !important;
    font-weight:bold;
    font-size:20px !important;
    position: relative;
    top:-12px;
}

.location-listing {
    @include resetList;
}
.location-listing__item {
    border-bottom:1px solid #999;
    padding:0 0 30px;
    margin:0 0 30px;

    &:last-child {
        border:0;
        margin:0;
        padding:0;
    }
}
.location-item {
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
}
.location-item__number {
    width:40px;
    position: relative;
}
.location-item__number-svg {
    width:40px;
    height:60px;
    fill:$color-primary;
}
.location-item__number-text {
    color:$color-invert;
    font-family: $font-tertiary;
    font-weight:bold;
    @include x-rem(font-size, 20px);
    position: absolute;
    top:10px;
    left:50%;
    transform: translateX(-50%);
}
.location-item__number-text-icon {
    width:19px;
    height:19px;
    fill:$color-invert;
}
.location-item__details {
    width:calc(100% - 60px);
}
.location-item__details-top {
    display: flex;
    justify-content: space-between;
}
.location-item__name {
    @include x-rem(font-size, 20px);
    font-family: $font-tertiary;
    font-weight:bold;
}
.location-item__name-link {
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &.has-arrow:after {
        display:inline-block;
    }
}
.location-item__distance {
    white-space: nowrap;
    font-family: $font-tertiary;
    font-weight:bold;
}

.location-item__details-block {
    display:flex;
    flex-flow: column;

    p {
        @include x-rem(font-size, 16px);
        margin:0 0 10px;
    }
    strong {
        display:block;
        margin:0 0 2px;
    }

    .button-ghost {
        margin:0 0 20px;
        white-space: nowrap;
    }
}
.location-item__link {
    display:inline-flex;
    font-weight:600;
    text-decoration: none;
    align-items: center;
    margin:0 0 5px;
    overflow:auto;

    &.location-item__link--normal {
        font-weight:normal;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
.location-item__link-icon {
    width:16px;
    height:16px;
    margin:0 5px 0 0;
    float:left;
}
.location-item__link-arrow {
    width:18px;
    height:18px;
    margin:0 0 0 2px;
}
@media (min-width: $bp-m) {
    .location-item__details-bottom {
        display:flex;
        justify-content: space-between;
        margin:0 -15px;
    }
    .location-item__details-block {
        padding:0 15px;
        width:calc(50% - 121px);

        &:nth-child(3) {
            width:242px;
        }
    }
}
@media (min-width: $bp-mw) {
    #js-dealers-error,
    #js-dealers-radius-error {
        @include x-rem(font-size, 20px);
    }
}

@media (min-width: $bp-l) {
    .location-listing-container {
        display:flex;
    }
    .location-listing-container__map {
        order:2;
        width:40%;
        margin:0;
    }
    .location-listing-container__listing {
        width:60%;
        padding-right:30px;
    }
    .location-listing {
        @include resetList;
        max-height:685px;
        padding-right:20px;
        overflow-y:auto;
    }
}

.location-details-top__map {
    background:#ddd;
    height: 300px;
}
.location-details-top__details {
    background:#F1F1F1;
}
.location-details-top__details-inner {
    padding:30px 15px;
}
.location-details-top__helpers {
    margin:0 0 10px;
}
.location-details-top__helpers-link {
    font-family: $font-tertiary;
    text-decoration: none;
    display:flex;
    font-weight: bold;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
.location-details-top__helpers-link-icon {
    width:22px;
    height:22px;
    margin:0 5px 0 0;
    fill:currentColor;
}
.location-details-top__details-heading {
    @include x-rem(font-size, 30px);
    margin:0 0 10px;
}

.location-details-top__block {
    p {
        margin:0 0 10px;
    }
    strong {
        display:block;
        margin:0 0 2px;
    }
}
.location-details-top__link {
    display: inline-flex;
    font-weight: 600;
    text-decoration: none;
    align-items: center;
    margin: 0 0 6px;

    &.location-details-top__link--normal {
        font-weight:normal;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
.location-details-top__link-icon {
    width: 16px;
    height: 16px;
    margin: 0 5px 0 0;
    fill:currentColor;
}

@media (min-width: $bp-mw) {
    .location-details-top {
        display:flex;
    }
    .location-details-top__map {
        width:50%;
        height: auto;
    }
    .location-details-top__details {
        width:50%;
        display: flex;
        justify-content: flex-end;
    }
    .location-details-top__details-inner {
        max-width:698px;
        width:100%;
        padding:30px 50px 30px 15px;
    }
    .location-details-top__helpers {
        display:flex;
        justify-content: space-between;
        margin:0 0 30px;
    }
    .location-details-top__details-heading {
        @include x-rem(font-size, 46px);
    }
    .location-details-top__blocks {
        display:flex;
        margin:0 -25px;
        @include x-rem(font-size, 20px);
    }
    .location-details-top__block {
        padding:0 25px;
        width:50%;
    }
}

/* ribbons */

.appointment-ribbon {
    background:$color-primary;
    text-align: center;
    padding:30px 0;
}
.appointment-ribbon__container {

    &:before,
    &:after {
        display:none;
    }
}
.appointment-ribbon__heading {
    color:$color-invert;
    @include x-rem(font-size, 24px);
    margin:0 0 10px;
}
.appointment-ribbon__description {
    color:$color-invert;
    margin:0 0 20px;
    @include x-rem(font-size, 20px);
}

@media (min-width: $bp-mw) {
    .appointment-ribbon {
        text-align: left;
        padding:35px 0;
    }
    .appointment-ribbon__container {
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
    .appointment-ribbon__heading {
        margin:0;
    }
    .appointment-ribbon__description {
        padding:0 20px;
        margin:0;
    }
    .appointment-ribbon__button {
        white-space: nowrap;
    }
}

.alert-ribbon {
    background:#f1f1f1;
    padding:15px;
}

.alert-ribbon__heading {
    @include x-rem(font-size, 20px);
    margin:0 0 5px;
    font-family: $font-tertiary;
}
.alert-ribbon__description {
    @include x-rem(font-size, 20px);
    margin:0;
}

@media (min-width: $bp-mw) {
    .alert-ribbon__container {
        display:flex;
        align-items: flex-end;
        justify-content: center;
    }
    .alert-ribbon__heading {
        white-space: nowrap;
        margin:0
    }
    .alert-ribbon__description {
        @include x-rem(font-size, 16px);
        margin:0 0 2px 10px;
    }
}

/* Team member list */

.team-list {
    @include resetList;
}
.team-list__item {
    margin:0 0 15px;
}
.team-list__link {
    display:flex;
    align-items: center;
    text-decoration: none;

    &:hover,
    &:focus {
        .team-list__name {
            text-decoration: underline;
        }
    }
}
.team-list__image {
    width:80px;
}
.team-list__content {
    width:calc(100% - 80px);
    padding:0 0 0 15px;
}
.team-list__name {
    color:$color-primary;
    font-family: $font-tertiary;
    font-weight:bold;
    display:inline-flex;
    align-items: center;
    margin:0 0 3px;
}
.team-list__title {
    color:#333;
}

@media (min-width: $bp-sw) {
    .team-list {
        display:flex;
        flex-wrap: wrap;
        margin:0 -20px;
    }
    .team-list__item {
        width:50%;
        padding:0 20px 15px;
        margin:0;
    }
    .team-list__link {
        display:block;
    }
    .team-list__image {
        width:auto;
    }
    .team-list__content {
        width:100%;
        padding:15px 0 0 0;
    }
}
@media (min-width: $bp-m) {
    .team-list__item {
        width:25%;
    }
    .team-list__name {
        @include x-rem(font-size, 20px);
    }
}

.interior-bottom {
    background-color: $bg-location;
}

.all-locations__heading {
    text-align: center;
    margin-top: 30px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;
}

.all-locations__subheading {
    text-align: center;
    color: $color-base;
    font-family: $font-primary;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 500;
}

.all-locations__input {
    height: 46px;
    border-radius: 4px;
    border: 1px solid $color-gray;
    height: 46px;
    border-radius: 4px;
    color: $color-base;
    font-family: $font-primary;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
    appearance: none;    
    width: 100%;

    &::-ms-expand {
        display:none;
    }
}

.all-locations__text {
    text-align: center;
    padding-top: 20px;
}

.state-icon-chevron-down {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 20px;
    max-height: 20px;
    pointer-events: none;
}

.city-icon-chevron-down {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 20px;
    max-height: 20px;
    pointer-events: none;
}

.all-locations-button-container {
    min-width: 320;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
}

.button-locations {
    padding: 13px 20px 13px 20px;
    margin: 20px auto;
    min-width: 250px;
}

.all-locations-wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

@media (min-width: $bp-m) { 
    .all-locations__heading { 
        border-bottom: none;
        padding-bottom: 0;
    }

    .all-locations { 

        display: flex;
        align-items: center;
    }

    .all-locations-search {
        max-width:900px;
        margin: 0 auto;
        padding-bottom: 20px;
    }

    #locationRegions {
        display:flex;
        width:100%;
        align-items: center;
    }

    .all-locations__text {
        margin-left: 20px;
        margin-right: 20px;
    }

    .all-locations-button-container {
        margin-left: 20px;
    }

    .button-locations {
        min-width: 100px;
    }
}

.all-locations-listing-grid {
    list-style: none;
    padding:0;
    margin:0;
}

.all-locations-listing-item {
    margin:0 0 15px;
}

.listing-item-card {
    margin:0 auto;
    border-radius: 4px;
    background-color: $body-bg;
    box-shadow: 0 4px 7px 0 rgba(0,0,0,0.3);
    padding: 30px;
    height:100%;

    .location-item__details {
        width:auto;
    }
}

.locations-item__min-width {
    min-width: 180px;
}

.all-locations-listing {    
    margin: 0 auto;
    
    .location-item {
        display: block;
    }

    .location-item__details-bottom {
        display: block;
    }

    .location-item__details-block {
        width: 100%;
    }
}


.all-locations-city__heading {
    color: $color-primary;
    font-family: $font-secondary;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.6px;
    line-height: 56px;
    border-bottom: 1px solid $color-secondary;
}



.all-locations-listing {
    border-top: none;
    padding-top: none;
}
@media (min-width: $bp-m) {
    .all-locations-listing-grid {
        display: flex;
        margin: 0px -15px;
        flex-wrap: wrap;
    }
    .all-locations-listing-item {
        width:50%;
        padding:0 15px;
        margin:0 0 30px;
    }
}
@media (min-width: $bp-mw) {
    .all-locations-listing {
        border-top: 1px solid #999999;
        padding-top: 20px;
    }

    .all-locations-listing-item {
        width:33.33%;
    }
}
.location-item_mylocation {
    color:$color-primary;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom:5px;
    margin-top:5px;
}
.location-item__icon-star {
    height: 13px;
    width: 13px;
    fill: $color-primary;
    &--white {
        fill: $body-bg;
    }
}

.atm-location__titles {
    padding-top: 5px;
    border-top: 1px solid rgba(0, 33, 71, 0.16);
}

.atm-location-container {
    box-shadow: 0px 3px 6px -2px #333;
    margin: 0 0 30px;
    background:#f1f1f1;
    border-radius: 4px;
    padding: 25px 40px;
}

.atm-location__heading {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.6px;
}
.atm-location__my-location {
    color:$color-primary;
    margin:0 0 15px;

    strong {
        font-weight:500;
        padding:0 5px 0 0;
    }
    span {
        padding:0 3px;
    }
    a {
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}
.atm-location__searching-area {
    display: block;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.atm-location-input-container {
    width: 100%;
    display: flex;
}
.atm-location__input {
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    background-color: $color-invert;
    font-family: $font-primary;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
    width: 100%;
    margin: 0 auto;
    height: 46px;
  }
.atm-location__button-search-container {
    display: flex;
    margin-top: 20px;
    width: 100%;
}
.atm-location__button-view-container {
    display: flex;
    margin-top: 20px;
  }
.button-search-atm {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
}
.button-view-atm {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;    
    border: 1px solid $color-primary;
    border-radius: 4px;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    padding: 12px 24px;
    text-decoration: none;

    &:hover,
    &:focus {
        background:$color-primary;
        color:$color-invert;
    }
}

.button-view-atm,
.button-search-atm {
    max-width: 240px;
}
@media (min-width: $bp-mw) {
    .atm-location__searching-area {
        display: flex;
        justify-content: space-between;
    }

    .atm-location__button-search-container {
        margin-top: 0;
        margin: 0 20px 0 20px;
        min-width: 150px;
        width: unset;
    }

    .atm-location__button-view-container {
        margin-top: 0;
    }

    .button-view-atm,
    .button-search-atm {
        max-width: unset;
    }

    .atm-location__button-view-container {
        min-width: 200px;
    }

    .atm-location__heading {
        text-align: left;
    }
}