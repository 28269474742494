.newsletter {
  @include x-rem(padding, 28px 0);
  @include breakpoint($bp-m) {
    @include x-rem(padding, 70px 0);
  }
}
.newsletter-listing--grid {
  @include resetList;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include x-rem(gap, 28px);
  @include breakpoint($bp-m) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoint($bp-mw-max) {
    grid-template-columns: repeat(3, 1fr);
    @include x-rem(gap, 55px);
  }
}
.newsletter-listing--grid .newsletter-listing__item {
  display: flex;
  flex-direction: column;
}
.newsletter-listing--grid .newsletter-listing__content {
  flex: 1;
}
.newsletter__title {
  text-align: center;
  @include x-rem(margin-bottom, 28px);
  letter-spacing: -1.25px;
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 38px);
    @include x-rem(font-size, 50px);
    @include x-rem(line-height, 56px);
  }
}

//filters
.newsletter__filterbox {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 7px);
  @include x-rem(margin-top, 5px);
  @include x-rem(margin-bottom, 60px);
  border: 1px solid $color-gray4;
  border-radius: 4px;
  background-color: $body-bg;
  @include breakpoint(75em) {
    margin-top: 0;
    flex-direction: row;
    align-items: center;
    border: 1px solid transparent;
  }
}

.newsletter__filter-btn {
  @include resetBtn;
  border-radius: 4px;
  font-weight: $fontWeight-medium;
  text-align: left;
  width: 100%;
  @include x-rem(padding, 12px 10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include x-rem(gap, 5px);
  @include breakpoint(75em) {
    width: fit-content;
    pointer-events: none;
    padding: 0;
  }
}

.newsletter__filter-label {
  @include x-rem(font-size, 13px);
  @include x-rem(line-height, 17px);
  font-weight: $fontWeight-medium;
  font-family: $font-primary;
  &--mobile {
  }
  &--desktop {
    display: none;
    font-size: 1rem;
    @include x-rem(padding, 12px 13px);
  }
  @include breakpoint(75em) {
    &--mobile {
      display: none;
    }
    &--desktop {
      display: flex;
    }
  }
}
.newsletter__current-filter {
  text-transform: uppercase;
  @include breakpoint(75em) {
    display: none;
  }
}
.newsletter__filter-btn {
  svg {
    @include x-rem(height, 8px);
    @include x-rem(width, 12px);
    transition: $trans;
  }
  &:hover,
  &:focus {
    outline: 1px solid $color-base;
    svg {
      transform: rotate(-180deg);
    }
  }
  @include breakpoint(75em) {
    svg {
      display: none;
    }
  }
}
.newsletter__filter-btn.active {
  svg {
    transform: rotate(-180deg);
  }
}
.filter-listing__wrapper[data-expander-target] {
  @include breakpoint(75em) {
    display: flex;
    flex: 1;
  }
}
.filter-listing1 {
  @include resetList;
  display: flex;
  flex-direction: column;

  @include breakpoint(75em) {
    justify-content: space-between;
    flex-direction: row;
    @include x-rem(gap, 2px);
    width: 100%;
  }
}

.filter-listing1__btn {
  @include resetBtn;
  @include x-rem(padding, 12px 13px);
  color: $color-base;
  font-size: 1rem;
  font-weight: $fontWeight-medium;
  text-align: center;
  &:hover,
  &:focus,
  &.active {
    color: $color-invert;
    background-color: $color-primary;
  }
}

// custom checkbox
.filter-listing1__label {
  width: 100%;
  text-align: left;
  cursor: pointer;
  @include x-rem(padding, 12px 13px);
  color: $color-base;
  font-size: 1rem;
  font-weight: $fontWeight-medium;
  transition: $trans;
  display: inline-block;
  @include x-rem(margin-top, -15px);
  @include breakpoint(75em) {
    width: initial;
    text-align: center;
    margin-top: 0;
  }
}
.filter-listing1__input[type="checkbox"] {
  appearance: none;
  height: 0;
  width: 0;
  opacity: 0;
  margin: 0;
  border: none;
}

.filter-listing1__input[type="checkbox"] {
  &:hover,
  &:focus {
    + label {
      color: $color-invert;
      background-color: $color-primary;
    }
  }
}
.filter-listing1__input.focus-overlay-target {
  &::after {
    content: none;
  }
}

.filter-listing1__input[type="checkbox"]:checked + label {
  color: $color-invert;
  background-color: $color-primary;
}
.filter-listing1__input[type="checkbox"]:checked {
  &:hover,
  &:focus {
    + label {
      background: transparent;
      color: $color-base;
      outline: 1px solid $color-primary;
    }
  }
}

.newsletter__cta {
  @include x-rem(margin-top, 30px);
}
