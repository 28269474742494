.sitemap__link--large {
    display: block;
    font-size: 1.5em; /* Or any other styling you wish to make the link appear larger */
    font-weight: $fontWeight-bold;
    margin-bottom: .75rem;
}

.sitemap-tb {
    @include x-rem(padding, 40px 0);
}

.sitemap {
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
        list-style-type: none;
    }

    > li {
        width: 100%;
        margin: 0 0.5rem 2rem 0.5rem;

        @include breakpoint(m) {
            width: calc(100% * (1 / 2) - 1rem);
        }

        @include breakpoint(l) {
            width: calc(100% * (1 / 3) - 1rem);
        }

        @include breakpoint(xl) {
            width: calc(100% * (1 / 4) - 1rem);
        }
    }

    .sitemap__no-bullet {
        display: none;
    }
}

.sitemap-tb__title {
    @include x-rem(margin-bottom, 20px);
    @include x-rem(font-size, 24px);
    font-weight: normal;
}

.sitemap__sub {
    @include resetList;

    .sitemap__sub {
        display: block;
    }
}

.sitemap__sub li {
    width: 100%;

    &.sitemap__no-bullet {
        list-style-type: none;
    }
}

.sitemap__heading,
.sitemap__link {
    display: inline-block;
    font-weight: $fontWeight-bold;
    @include x-rem(margin-bottom, 10px);
    @include x-rem(font-size, 14px);
}

.sitemap__heading {
    margin-top: 1rem;
    @include x-rem(font-size, 18px);
}

.sitemap__link {
    &--large {
        @include x-rem(font-size, 18px);
    }
}

.sitemap__sub {
    .sitemap__link {
        margin: 0;
        font-weight: normal;
    }
}
