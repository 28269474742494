/* ================================================================
   Videos
   ================================================================ */

.video-container { 
    position: relative; 
    padding-bottom: 51.25%; 
    padding-top: 30px; 
    height: 0; 
    overflow: hidden; 

    &.video-container--content {
        margin:0 0 30px;

        + .video-transcript {
            margin:-25px 0 20px;
        }
    }
}
.landing-listing-container--with-videos .video-container--content {
    margin:0;

    + .video-transcript {
        margin:5px 0 15px;
    }
}
.video-container iframe, .video-container object, .video-container embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    border:0;
}
.video-transcript {
    text-align:right;
    margin:5px 0 0 0;
}
.video-container + .video-transcript {
    
}

.video-stage {
    
}

.video-stage__video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
    height: 0;
    border:4px solid $color-primary;
}
.video-stage__video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.video-stage__info {
    background:$color-primary;
    padding:15px 20px;
    color:$color-invert;
    margin:0 0 20px;
}
.video-stage__description {
    margin:0;
}

.video-listing {
    display:flex;
    margin:0;
    padding:0;
    list-style:none;
    flex-wrap: wrap;
    margin:0 -10px;
}
.video-listing__item {
    width:100%;
    padding:0 10px;
    margin:0 0 20px;
}
.video-link {
    display:block;
    height:200px;
    position:relative;
    background-size:cover;
    background-position:50% 50%;
    color:$color-invert;
    text-decoration:none;
    text-align:center;

    &.active {
        .video-link__title {
            background:$color-secondary;
        }
    }

    &:hover,
    &:focus {
        transform: scale(1.05);
        .video-link__title {
            background:$color-secondary;
        }
    }
}
.video-link__title {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    background:$color-primary;
    padding:10px;
}

@media (min-width: $bp-sw) {
    .video-listing__item {
        width:50%;
    }
}
@media (min-width: $bp-m) {
    .video-listing__item {
        width:33%;
    }
}
@media (min-width: $bp-mw) {
    .video-listing__item {
        width:25%;
    }
}


/* Video Modal */

.video-modal-button {
    width:100%;
    position: relative; 
    padding:0;
    padding-bottom: 56.25%; 
    padding-top: 30px; 
    height: 0; 
    overflow: hidden; 
    border:0;
    margin:0 0 30px;
    background-size: cover;
    background-position: 50% 50%;
    cursor:pointer;

    &:before {
        content:'';
        border:5px solid $color-invert;
        border-radius: 50%;
        width:70px;
        height:70px;
        position: absolute;
        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%);
    }
    &:after {
        content:'';
        border-top:17px solid transparent;
        border-bottom:17px solid transparent;
        border-left:25px solid $color-invert;
        position: absolute;
        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%);
        margin:0 0 0 4px;
    }

    &:hover,
    &:focus {
        &:before {
            border:5px solid $color-primary;
        }
        &:after {
            border-left:25px solid $color-primary;
        }
    }
}
.landing-listing-container--with-videos .video-modal-button {
    margin:0;
}
.video-modal__content {
    position: absolute;
    width:100%;
    bottom:0;
    left:0;
    padding:20px;
    background:transparentize($color: $color-primary, $amount: .3);
}
.video-modal_heading {
    color:$color-invert;
}
.video-modal_description {
    color:$color-invert;
}

.video-modal-listing {
    list-style: none;
    margin:0;
    padding:0;
    display:flex;
    flex-wrap: wrap;
}
.video-modal-listing__item {
    width:100%;
    padding:0 10px;
    margin:0 0 20px;
}
.video-modal-listing__heading {
    @include x-rem(font-size, 22px);
}
.video-modal-listing__description {
    @include x-rem(font-size, 18px);
}

@media (min-width: $bp-sw) {
    .video-modal-listing__item  {
        width:50%;
    }
}
@media (min-width: $bp-m) {
    .video-modal-listing__item  {
        width:33%;
    }
}
@media (min-width: $bp-mw) {
    .video-modal-listing__item  {
        width:25%;
    }
}