/* ================================================================
   Cookie Consent
   ================================================================ */

.cookie-consent {
    position: fixed;
    width:100%;
    left:0;
    bottom:0;
    background:$color-invert;
    padding:20px 0;
    z-index:999;
    border-top:1px solid #eee;
}
.cookie-consent__desc {
    color:$color-base;
    margin:0 0 1rem;

    a {
        color:inherit;
    }
}
.cookie-consent__submit {
    @extend .button-2;
}