$hero-bp: 62.5em; //1000px
.newsletter-page-wrapper {
  @include x-rem(padding-top, 39px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-top, 53px);
  }
}

// detail page hero

.news-article-hero {
  display: flex;
  flex-direction: column;
  @include x-rem(margin-bottom, 40px);

  @include breakpoint($hero-bp) {
    @include x-rem(margin-bottom, 70px);

    &.article-collapsed {
      align-items: center;
    }
    &.article-row {
      flex-direction: row;
    }
  }
}
.news-article-hero__imgbox {
  @include bgImg;
  flex: 1;
  width: 100%;
  @include x-rem(min-height, 219px);
  position: relative;
  &:after {
    position: absolute;
    top: 104px;
    right: -16px;
    width: 100px;
    height: 3px;
    background: $color-secondary;
  }
  @include breakpoint($hero-bp) {
    @include x-rem(min-height, 640px);
  }
}

.article-row {
  @include breakpoint($hero-bp) {
    .news-article-hero__imgbox {
      flex: initial;
      width: 100%;
      @include x-rem(max-width, 1141px);
      &:after {
        content: "";
      }
    }
  }
}

.news-article-hero__textbox {
  background: $color-primary;
  @include x-rem(padding, 27px 20px);
  @include breakpoint($hero-bp) {
  }
}
.article-row {
  @include breakpoint($hero-bp) {
    .news-article-hero__textbox {
      @include x-rem(padding, 74px 40px);
      flex: 1;
    }
  }
}
.article-collapsed {
  @include breakpoint($hero-bp) {
    .news-article-hero__textbox {
      width: 100%;
      position: relative;
      z-index: 4;
      @include x-rem(max-width, 994px);
      border-radius: 4px;
      @include x-rem(padding, 71px 37px 58px 71px);
      @include x-rem(margin-top, -283px);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.news-article-hero__quote-icon {
  @include x-rem(height, 37px);
  @include x-rem(width, 46px);
  @include x-rem(margin-bottom, 15px);
  @include breakpoint($hero-bp) {
    @include x-rem(height, 55px);
    @include x-rem(width, 68px);
    @include x-rem(margin-bottom, 40px);
  }
}

h1.news-article-hero__quote {
  color: $color-invert;
  font-style: italic;
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 26px);
  letter-spacing: -0.45px;
  font-family: $font-secondary;
  margin: 0;
  text-align: center;
  @include breakpoint($hero-bp) {
    text-align: left;
    @include x-rem(font-size, 30px);
    @include x-rem(line-height, 46px);
    letter-spacing: -0.75px;
  }
}
.article-row {
  @include breakpoint($hero-bp) {
    h1.news-article-hero__quote {
      width: 100%;
      @include x-rem(max-width, 425px);
    }
  }
}
// body content

// background
.news-detail-page {
  &.color-gold {
    background: $color-gold2;
  }
  &.color-light-blue {
    background: $color-teal;
  }
  &.color-light-gray {
    background: $bg-location;
  }
  &.color-white {
    background: $body-bg;
  }
}

.news-detail-page__title {
  font-family: $font-secondary;
  @include x-rem(margin-bottom, 17px);
  text-align: center;
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 22px);
    text-align: left;
  }
}
.news-detail-page__subtitle {
  font-family: $font-secondary;
  color: $color-gray3;
  @include x-rem(margin-bottom, 17px);
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 59px);
  }
}
.news-detail-page__desc {
  color: $color-gray3;
  p:last-child {
    margin: 0;
  }
}

.news-detail-page {
  // background: $bg-location;
  @include x-rem(padding, 24px 0 30px 0);
  @include breakpoint($bp-m) {
    @include x-rem(padding, 70px 0 32px 0);
  }
}

.newsletter-detail__sharebox {
  @include x-rem(margin-top, 21px);
  @include x-rem(margin-bottom, 45px);
  display: flex;
  flex-direction: column;
  align-items: center;
  @include x-rem(gap, 22px);

  @include breakpoint($bp-m) {
    flex-direction: row;
    @include x-rem(gap, 40px);
    @include x-rem(margin-top, 48px);
    @include x-rem(margin-bottom, 72px);
  }
}
.newsletter-detail__sharebox p {
  margin: 0;
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 26px);
  font-weight: $fontWeight-medium;
}
.newsletter-socials {
  @include resetList;
  display: flex;
  @include x-rem(gap, 25px);
}
.newsletter-detail__bottom {
  display: flex;
  @include x-rem(gap, 10px);
  justify-content: space-between;
  @include x-rem(margin-top, 45px);
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 70px);
  }
}
.newsletter-detail__nav-links {
  display: flex;
  justify-content: space-between;
  @include x-rem(gap, 78px);
}
.newsletter-detail__nav-link {
  text-decoration: none;
  display: flex;
  align-items: flex-start;
  color: $color-base;
  @include x-rem(font-size, 18px);
  @include x-rem(gap, 3px);
  font-weight: $fontWeight-medium;
  text-decoration: none;
  text-transform: uppercase;
  @include breakpoint($bp-m) {
    align-items: center;
    @include x-rem(font-size, 20px);
    @include x-rem(gap, 6px);
  }
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.newsletter-detail__nav-link svg {
  @include x-rem(height, 17px);
  @include x-rem(width, 24px);
  @include breakpoint($bp-m) {
  }
}
