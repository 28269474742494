.breadcrumbs {
    display: none;
    @include resetList;
    @include x-rem(font-size, 16px);
}

.breadcrumbs__item {
    position:relative;
    display: inline-block;
    padding-right: 18px;
    color:$color-primary;

    &:before {
        content:'';
        width:2px;
        height:7px;
        background:currentColor;
        position: absolute;
        right:6px;
        top:9px;
        transform: rotate(45deg);
    }
    &:after {
        content:'';
        width:2px;
        height:7px;
        background:currentColor;
        position: absolute;
        right:6px;
        top:5px;
        transform: rotate(-45deg);
    }

    &:last-child {
        padding: 0;

        &::before,
        &::after { 
            display: none;
        }
    }
}

.breadcrumbs__link {
    display: inline-block;
    text-decoration: none;
    font-weight:500;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.breadcrumbs__active {
    font-weight: normal;
}

@media (min-width: $bp-mw) {
    .breadcrumbs {
        display: block;
    }
}