/* ================================================================
   Typography Styles
   ================================================================ */


h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	margin:0 0 15px;
	font-family:$font-heading;
	color:$color-primary;
	line-height:1.2;
}

h1, .h1 { @include x-rem(font-size, 34px); }
h2, .h2 { @include x-rem(font-size, 30px); }
h3, .h3 { @include x-rem(font-size, 24px); }
h4, .h4 { @include x-rem(font-size, 20px); }
h5, .h5 { @include x-rem(font-size, 18px); }
h6, .h6 { @include x-rem(font-size, 16px); }

a {
	color:$color-primary;
	text-decoration:underline;
	transition: $trans;

	&:hover, &:focus { text-decoration:none; }
}

.icon-external-link {
	width:14px;
	height:14px;
	fill:currentColor;
	display: inline-block;
	margin: 0 0 0 7px;
}

.has-arrow {
	&:after {
        content:'';
        background-image:url(../images/right-arrow.svg);
        width:8px;
        height:12px;
        margin:0 0 0 5px;
    }
}

.has-arrow-callout,
.has-arrow-callout-rte a {
	display:inline-flex;
	font-weight:700;
	text-decoration: none;
	align-items: center;
	font-family: $font-tertiary;

	&:after {
        content:'';
        background-image:url(../images/right-arrow.svg);
        width:8px;
        height:12px;
		margin:0 0 0 5px;
	}
	
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}




p { margin:0 0 $typography-margin-bottom; }

// Very simple blockquote styling
blockquote, .quote {
	quotes: "“" "”" "‘" "’";
	border:0 !important;
	margin:0 0 30px !important;
	padding:0 !important;
	color:$color-primary !important;
	@include x-rem(font-size, 20px);
	display:flex;
	justify-content: center;
	text-align: center;

	&:before {
		content: open-quote;
		font-weight:bold;
		font-size: 2.4em;
		margin:0 5px 0 0;
		font-family:$font-secondary;
		line-height:.8;
	}
	&:after {
		content: close-quote;
		font-weight:bold;
		font-size: 2.4em;
		margin:0 0 0 5px;
		font-family:$font-secondary;
		align-self: flex-end;
		line-height:.8;
	}

	p {
		display:inline;
	}

	blockquote {
		display:inline;

		&:before {
			display:none;
		}
		&:after {
			display:none;
		}
	}
}
.disclaimer {
	border-bottom:1px solid #999;
	padding:0 0 20px;
	margin:0 0 30px;
	color:#333;
	@include x-rem(font-size, 16px);
}

hr {
	border-top:1px solid #999;
	margin:30px 0;
}

.smallest { font-size:0.8em; }
.smaller { font-size:0.9em; }
.larger { font-size:1.1em; }
.largest { font-size:1.2em; }

.bold { font-weight:bold; }
.italic { font-style:italic; }
.strike { text-decoration:line-through; }

.nowrap { white-space:nowrap; }
.important { color:red; font-weight:bold; text-transform:uppercase; }

.title {
	@include fluid-type($bp-s, $bp-mw, 20px, 30px);
	font-weight: $fontWeight-semibold;
	margin: 0 0 5px;
}

.interior-content-main,
.fr-view {
	line-height:1.6;
	
	p, ul, ol {
		margin:0 0 25px;
	}

}

@media (min-width: $bp-mw) {
	h1, .h1 { @include x-rem(font-size, 50px); }
	h2, .h2 { @include x-rem(font-size, 40px); }
	h3, .h3 { @include x-rem(font-size, 32px); }
	h4, .h4 { @include x-rem(font-size, 26px); }
	h5, .h5 { @include x-rem(font-size, 22px); }
	h6, .h6 { @include x-rem(font-size, 18px); }

	hr {
		margin:45px 0;
	}

	.interior-content-main,
	.fr-view {
		@include x-rem(font-size, 20px);

		p, ul, ol {
			
		}
	}

	blockquote, .quote {
		@include x-rem(font-size, 22px);
	}

	//Calculator 
	#KJECalculatorDescID {
		font-size:20px !important;
	}
	.KJEDefinitions {

	}
	h3.KJEDefinition {
		font-size:32px !important;
	}
	p.KJEDefinition {
		font-size:20px !important;
	}
}