.related-articles-list{
    display: none;
}

@media (min-width: $bp-m-max) {
    .articles-card-body {
        padding: 0 20px 20px 20px;
        min-height: 120px;
    }

    .related-articles-list {
        display: flex;
        justify-content: space-between;
    }
    
    .related-articles-grid {
        width: 100%;
        display: flex;
        padding: 50px 0;
        margin: 0px -20px;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    
    .related-articles-item {
        flex-basis: 1 0 50%;
        padding: 0 20px;
        max-width: 50%;
        margin-bottom: 40px;
        list-style-type: none;
    }

    .articles-card {
        border-radius: 4px;
        background-color: $color-gray;
        box-shadow: 0 4px 7px 0 rgba(0,0,0,0.3);
    }

    .articles-card-subtitle {
        color: $color-base;
        font-family: $font-primary;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
    }

    .articles-card-title {
        color: $color-primary;
        font-family: $font-primary;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 26px;
        min-height: 92px;
        text-decoration: none;
    }

    .articles-card-title::after {
        content: ">";
        padding: 5px;
    }
    
    .articles-card-title:hover {
        &::after {
            padding: 10px;
        }
    }

    .articles-card-title {
        &:focus,
        &:hover{
            text-decoration: underline;
        }
    }

    .articles-card-title:focus {

    }
}

@media (min-width: $bp-mw) {

    .related-articles-grid {
        width:100%;
        display: flex;
        padding: 40px 0;
        margin: 0px -10px;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    
    .related-articles-item {
        flex-basis: 1 0 25%;
        padding: 0 20px;
        max-width: 25%;
        margin-bottom: 40px;
        list-style-type: none;
    }

    .articles-card-title {
        min-height: 72px;
    }
}

.all-locations-search-grid {
    display: flex;
}

.all-locations {
    margin: 0 auto;
}