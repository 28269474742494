.curator-box {
}
.curator-box__top {
	text-align: center;
	margin-bottom: 50px;
}
.curator-box__title {
}
.curator-box__description {
	margin: 0;
}
.curator-box__container {
	position: relative;
}
.curator-box-list {
	@include resetList;
	display: flex;
	gap: 20px;
	&.slider {
		display: flex;
	}
	&.slider > * {
		width: calc(50% - 10px) !important;
		flex: 0 0 auto !important;
		@include breakpoint($bp-m) {
			width: calc(33.33333% - 14px) !important;
		}
		@include breakpoint($bp-mw) {
			width: calc(25% - 15px) !important;
		}
		@include breakpoint($bp-l) {
			width: calc(20% - 16px) !important;
		}
		@include breakpoint($bp-xl) {
			width: calc(16.67% - 17px) !important;
		}
	}
}
.curator-box-list__item {
}
.curator-box-list__button {
	@include resetBtn;
	border-radius: 20px;
	position: relative;
	overflow: hidden;
	padding-bottom: 140%;
	width: 100%;
	&::before {
		content: "";
		position: absolute;
		background: rgba($color-primary, 0.65);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 2;
	}
	&:hover,
	&:focus {
		.curator-detail__img-thumb {
			transform: scale(1.2);
			filter: blur(3px);
		}
	}
}
.curator-box-list__button .curator-detail__img-thumb {
	@include fitImage;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: $trans;
}
.curator-detail__col1 {
	padding-bottom: 60%;
	position: relative;
	@include breakpoint(62.5em) {
		flex-shrink: 0;
		padding-bottom: initial;
		width: 40%;
		max-height: calc(100vh - 85px);
	}
}
.curator-detail__col2 {
	padding: 15px;
	display: flex;
	flex-direction: column;
	flex: 1;
	@include breakpoint(62.5em) {
		padding: 30px;
		width: 60%
	}
}
.curator-detail__img {
	@include fitImage;
	position: absolute;
	top: 0;
	left: 0;
	@include breakpoint(62.5em) {
		position: initial;
	}
}
.curator-detail__svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 3;
	fill: $color-invert;
	width: 65px;
	height: 65px;
}
.curator-detail__top {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 15px;
	@include breakpoint(62.5em) {
		margin-bottom: 30px;
	}
}
.curator-detail__top-svg {
	width: 40px;
	height: 40px;
}
.curator-detail__link {
}
.curator-detail__bottom {
	margin-top: auto;
	border-top: 1px solid $bg-location;
	padding: 15px 0 0 0;
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: space-between;
	flex-wrap: wrap;
}
.curator-detail__text {
	margin: 0;
	display: flex;
	gap: 10px;
	align-items: center;
}
.curator-detail__comments {
	position: relative;
	display: flex;
	align-items: center;
	gap: 10px;
	&:before {
		content: "";
		height: 5px;
		width: 5px;
		display: flex;
		background: $color-primary;
		border-radius: 50%;
	}
}
// slider controls
.slider-controls-curator {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin: 20px 0;
}
.slider-controls-curator__arrow {
	@include resetBtn;
	border-radius: 50%;
	background: $color-primary;
	width: 50px;
	height: 50px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	z-index: 99;
	&:hover,
	&:focus {
		background: $color-secondary;
	}
}
.slider-controls-curator__arrow-icon {
	fill: $color-invert;
	width: 40px;
	height: 40px;
}
// modaal
.curator-modal {
	.modaal-container {
		max-width: initial;
		border-radius: 20px;
		overflow: hidden;
	}
	.modaal-inner-wrapper {
		padding: 30px;
	}
	.modaal-content-container {
		padding: 0;
		display: flex;
		flex-direction: column;
		gap: 15px;
		@include breakpoint(62.5em) {
			flex-direction: row;
			gap: 30px;
		}
	}
}