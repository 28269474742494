.a11y-slider-container {
    position: relative;
}

.a11y-slider {
    position: relative;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
    box-sizing: border-box;
}

.a11y-slider::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.a11y-slider > * {
    scroll-snap-align: start;
    box-sizing: inherit;
}

.a11y-slider-sr-only {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    text-align: center;
    font-family: inherit;
    font-size: 1em;
}

.a11y-slider-sr-only:focus {
    position: static;
    background: #000;
    color: #fff;
    width: 100%;
    height: auto;
    margin: 0;
    clip: auto;
    padding: 10px;
}

.a11y-slider-dots {
    @include resetList;
    display: flex;
    justify-content: center;
    font-size: 0;
    margin: 20px 0 0 0;

    > * {
        margin: 0 10px 0 0;

        &:last-child {
            margin: 0;
        }
    }

    button {
        @include resetBtn;
        background: #ddd;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        font-size: 0;
        text-indent: -9999px;
        transition: $trans;

        &:hover,
        &:focus {
            background: $color-primary;
        }

        &.active {
            background: $color-primary;

            &:hover,
            &:focus {
                background: lighten($color-primary, 4%);
            }
        }
    }
}

.a11y-slider-autoplay {
    @include resetBtn;
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 7px;
    border: none;
    font-size: 0;
    cursor: pointer;
    background: transparentize($color: $color-primary, $amount: 0.5);
    box-sizing: border-box;
    z-index: 1000;
    transition: $trans;
    opacity: 0;

    &::before {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        border-style: solid;
        border-width: 15px 0 15px 30px;
        border-color: transparent transparent transparent $color-invert;
        box-sizing: border-box;
        transition: $trans;
    }

    &[data-autoplaying="true"]::before {
        border-style: double;
        border-width: 0 0 0 30px;
    }

    &:hover,
    &:focus {
        background: transparentize($color: $color-primary, $amount: 0);
        opacity: 1;
    }

    @at-root {
        [class*='hero-slider']:hover &,
        [class*='hero-slider']:focus &,
        [class*='image-slider']:hover &,
        [class*='image-slider']:focus &,
        .a11y-slider-container:hover &,
        .a11y-slider-container:focus & {
            opacity: 1;
        }

        &:hover,
        &:focus {
            background: transparentize($color: $color-primary, $amount: 0);
            opacity: 1;
        }
    }
}

.a11y-slider-hide {
    display: none;
}
