/* Carrier list */
.carrier-list-wrapper {
    margin: 1rem 0;
}

.carrier-list {
    @include resetList;
    display: block;
    margin: 0 1rem;

    @include breakpoint(m){
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 1rem 0rem;
    }

    a {
        display: flex;
        align-items: center;
        width: 100%;
        margin: .5rem 0;

    }

    &__item {
        width: 100%;
        padding: 1rem;

        @include breakpoint(m) {
            width: calc(100% * (1/2) - 1rem);
        }
    }

    &__website {
        @include x-rem(font-size, 20px);
    }

    &__icon {
        width: 1rem;
        height: 1rem;
        margin-right: .5rem;
    }
}