/*================================================================
            CLICK MENU OVERWRITE MAIN NAVIGATION STYLES
================================================================*/

.main-nav .cm-menu {
    // Multi-level
    a {
        color: $color-primary;

        &.has-sub::after {
            border-top-color: currentColor;
        }
    }
    .main-nav__main-link{
        display: flex;
        flex-direction: row;
        align-items: center;
        @include x-rem(gap, 10.5px);
        font-weight: $fontWeight-bold;
        @include x-rem(margin-bottom, 11px); 
     }
    .main-nav__icon{
        @include x-rem(width, 30px);
        @include x-rem(height, 30px);
        fill: $color-primary;
        object-fit: contain;
     }
    a {
        @include x-rem(font-size, 18px);
        padding:10px 20px;

        &.has-sub {

            &:before {
                content:'';
                width:14px;
                height:2px;
                background:currentColor;
                position: absolute;
                right:17px;
                top:50%;
                transform: translateY(-50%);
            }
            &:after {
                border:0;
                margin:0;
                content:'';
                width:2px;
                height:14px;
                background:currentColor;
                position: absolute;
                right:23px;
                top:50%;
                transform: translateY(-50%);
            }
        }
    }
    
    &.main-nav__desktop{
        display: none;
        }
    .main-nav__col--mobile{
        display: none;
    }
    // 1st level
    > li {
        border:0;

        > a {
            color: $color-primary;
            @include x-rem(font-size, 18px);
            font-weight:bold;
            font-family:$font-tertiary;
            padding:15px 20px;
            background:$color-gray;
            border-bottom:1px solid #ccc;

            @at-root .main-nav--helper .cm-menu {

                > li > a {
                    font-weight:normal;
                    @include x-rem(font-size, 16px);

                    &.link-is-external {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
       
        &.opened > a {
            &:after {
                display:none;
            }
        }

        ul {
            background:#fff;

            li {
                border:0;

                a {
                    background:none;
                }
                &.opened > a {
                    &:after {
                        display:none;
                    }
                }

                li a {
                    @include x-rem(font-size, 16px);
                    padding:10px 20px 10px 30px;
                }
            }
        }
    }
[data-type="regular"]{
  ul{
    background: $color-gray;
    top: calc(100% + 30px);
  }
  ul  a { 
    &:hover, &:focus {
        background: $color-gray;
        color: $color-primary;
    } 
  }
     
}

    // Tablet/Desktop Styles
    @media (min-width: 93.75em) {
         
        // Multi-level
        position: static;
        background: none;

        // 1st level
        > li {
            > a {
                background: none;
                color: $color-invert;
                @include x-rem(font-size, 20px);
                border-radius:4px 4px 0 0;
                padding:10px 36px 0 36px ;
                position: relative;
                border:0 !important;
                text-decoration: none;

                &:hover,
                &:focus {
                    color:#E6D1A7;
                }

                &.has-sub:before,
                &.has-sub:after {
                    display:none;
                }
                &.has-sub span {
                    position: relative;
                    padding:0 20px 0 0;

                    &:before {
                        content:'';
                        position: absolute;
                        right:6px;
                        top:50%;
                        width:8px;
                        height:2px;
                        background:$color-invert;
                        transform: rotate(45deg) translateY(-50%);
                    }
                    &:after {
                        content:'';
                        position: absolute;
                        right:0;
                        top:50%;
                        width:8px;
                        height:2px;
                        background:$color-invert;
                        transform: rotate(-45deg) translateY(-50%);
                    }
                }
            } 
            
            &.opened > a {
                background: $body-bg;
                color:$color-primary;

                &:before {
                    content:'';
                    position: absolute;
                    top:100%;
                    left:0; 
                    width:100%;
                    height:30px;
                    z-index:11;
                    transform: none;
                    display:block;
                }

                &.has-sub span {
                    &:before,
                    &:after {
                        background:$color-primary;
                    }
                }
            }

            &[data-type="regular"].opened{
                 > a {
                    background: $color-gray;
                    &:before {
                        background: $color-gray;
                    }
                }
            }
            &[data-type="mega"] {
                &:last-child .main-nav__popup {
                    right: 0;
                    left: initial;
                    transform: none;
                }
            }
            &[data-type="mega"].opened{
                  > a {
                    background: $body-bg;
                    &:before {
                        background: $body-bg;
                    }
                }
            }

            // 2nd level
            > ul {
                top:calc(100% + 0px);
                background:$color-gray;
                width:320px;
                box-shadow:0px 0px 11px -4px #666;
                border-radius:0 5px 5px 5px;

                > li {
                    margin: 0;
                    border:0;

                    >  
                     
                    a {
                        color: $color-primary !important;
                        background:none;
                        @include x-rem(font-size, 18px);
                        padding:8px 35px;
    
                        &:hover,
                        &:focus { 
                            background: $color-gray5 !important;
                             
                            
                        }
                        &.has-sub:before,
                        &.has-sub:after {
                            display:none;
                        }
                        &.has-sub span {
                            position: relative;
                            display:flex;
        
                            &:before {
                                content:'';
                                position: absolute;
                                right:0px;
                                top: 9px;
                                width:8px;
                                height:2px;
                                background:$color-primary;
                                transform: rotate(-135deg);
                            }
                            &:after {
                                content:'';
                                position: absolute;
                                right:0px;
                                top: 14px;
                                width:8px;
                                height:2px;
                                background:$color-primary;
                                transform: rotate(-45deg);
                            }
                        }
                    }
                    &.opened > a {
                        background: $color-gray5;
                        color: $color-primary;
                        font-weight:500;
                    }
                } 
            }
            &.opened > ul {
                padding:25px 0; 
                ul {
                    left: auto;
                    right: 100%;
                    top:0;
                    padding:0;
                    background: #DEDFE0;
                    position: absolute; 
                    width: 100%;
                    min-width: 179px;  
                }
            }
            > ul ul {
                top:0;
                padding:0;
                background: #DEDFE0;
                position: absolute;
                left: auto;
                right: 100%; 
                

                > li {
                    margin:0;

                    a {
                        padding:9.5px 35px;
                        background:none;
                        color: $color-primary;
                       
                        &:hover,
                        &:focus {
                            color: $color-invert !important;
                            background:$color-primary !important;
                        }
                    }
                }
            }
        }





        @at-root .main-nav--helper {
            display:none;
        }
    }
    @media (min-width: 93.75em) {
        .main-nav__popup{
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
        &.main-nav__desktop{
            display: flex;
            }
        &.main-nav__mobile{
            display: none ;  
            }

    }
    // Mega menu styles
    .custom-sub {
        padding: 0;
        background:$color-invert;
        
        a, li, ul{
            padding: 0;
            margin: 0;
            background: none;
            max-height: none;
            opacity: 1;
        }

        li {
            border:0;
        }

        a {
            @include x-rem(font-size, 18px);
            padding:10px 20px;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
        .main-nav__sublinks a{
            @include x-rem(padding-left, 30px);
        }

        // Tablet/Desktop Styles
        @media (min-width: 93.75em) { 
            background: $body-bg;
            top: 100%;
            border-radius: 0 0 4px 4px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); 
            padding-top: 23px;
            &.main-nav__popup--minimize { 
                max-width: initial;
                left: 50%;
                top: 179%;
                transform: translateX(-50%);
                width: max-content;
            }
            .main-nav__sub-container{
                padding: 0;
                margin: 0;
                background: none;
                max-height: none;
                opacity: 1;
                 position: relative;
                 top: initial;
                 filter: none;

            }
            .main-nav__sub-container li {
                margin: 0;
                padding: 0;
            }
            .main-nav__sublinks{
                display: flex;
                flex-direction: column;
                @include x-rem(gap, 5px);
                @include x-rem(margin-top, 10px);
            }
            .main-nav__sublinks a, .main-nav__main-link {
                padding: 0;
                @include x-rem(font-size, 20px);
                @include x-rem(line-height, 24px);
                color: $color-primary;
                font-family: $font-tertiary;
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
            .main-nav__sublinks a{
                padding: 0;
                @include x-rem(font-size, 16px);
               
            }
             .main-nav__main-link{ 
                flex-direction: column;
                align-items: flex-start;
                min-width: 250px;
                &:before {
                    content: none;
                }
             }
             .main-nav__main-link--push{
                @include x-rem(margin-bottom, 50px); 
             }
             .main-nav__icon{
                @include x-rem(width, 50px);
                @include x-rem(height, 50px); 
             }
            .main-nav__wrapper{
                display: flex;
                 
            }
            .main-nav__col {
               flex: 1; 
               position: relative;
               @include x-rem(padding, 28.5px 31px );

               &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                opacity: .5;
                background: linear-gradient(180deg, #DAC188 0%, #E6D1A7 51.65%, #8B733F 100%);
                width:  1px ;
                height: calc(100% - 65px);
               }
               &:last-child {
                &:after{
                    content: none;
                }
               }
            }
            // 2nd level
            > ul {
                column-count:3;

                > li {
                    padding: 0 10px;
                    margin:0 0 10px;
                    overflow: hidden;

                    > a {
                        color: $color-primary;
                        @include x-rem(font-size, 18px);
                        padding:0;
                    }

                    // 3rd level
                    > ul {
                        max-height: none;
                        opacity: 1;

                        > li {
                            > a {
                                font-weight: bold;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }

        
    }
}
 
// For Towne Insurance Site only
.towneinsurance-site {
    .main-nav .cm-menu {
        @media (min-width: 93.75em) {

            &:last-child {
                margin-right: 1rem;
            }

            >li {
                >a {
                    @include x-rem(font-size, 20px);
                    padding: 10px 36px 0; 
                    padding-bottom: 1rem;

                    span {
                        display: inline-block;
                        width: max-content;
                    }

                    &.has-sub span {
                        position: relative;
                        padding: 0 20px 0 0;

                        &:before {
                            content: '';
                            top: .8rem;
                        }
                        &:after {
                            top: .8rem;
                        }
                    }
                }
            }
        }
    } 
}

// For TowneBank Newsletter only
@media (min-width: 93.75em) {
.main-nav__large .cm-menu  {
    .active-page{
        background: $color-secondary-light2;
    
        a{
           color: $color-primary;
           &:hover, &:focus {
            text-decoration: underline;
            color: $color-primary;
           }
        }
    }
    li[data-type="regular"] a{
        
            padding: 1rem .625rem;
        
    }
    justify-content: space-between;
    > li { 

        > a {  
            @include x-rem(font-size, 14px);
            white-space: nowrap;
            @include x-rem(padding, 16px 10px);
        }
    
    }
}
}
 