.resources-top {

}
.resources-top__item {
    background: $color-primary;
    padding:20px 20px;
    margin:0 0 15px;
    border-radius: 4px;
    color: #fff;

    h2, h3, a {
        color: #fff;
    }
    a::after {
        filter: brightness(0) invert(1);
    }

    ::selection {
        background-color: $color-secondary;
    }
}
.resources-top__meta-heading {
    @include x-rem(font-size, 14px);
    font-weight: bold;
    color:#333;
    margin:0 0 2px;
    font-family: $font-primary;
}
.resources-top__heading {
    @include x-rem(font-size, 32px);
    font-weight: bold;
    color:$color-primary;
    margin:0 0 2px;
    font-family: $font-primary;
    margin:0 0 10px;
}
.resources-top__image {
    height: 330px;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 4px;
    margin-bottom: 15px;
}
.resources-top__description {

    a {
        font-weight: bold;
        display:inline-flex;
        align-items: center;
        text-decoration: none;

        @extend .has-arrow;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

@media (min-width: $bp-mw) {
    .resources-top {
        display:flex;
        margin:0 -15px;
    }
    .resources-top__item {
        flex:1;
        padding:30px 40px;
        margin:0 15px 30px;
    }
}


// Listing

.resource-listing {
    margin: 0;
    padding: 0;
    list-style: none;
}
.resource-listing__item {
    margin-bottom:30px;
}
.resource-listing__block {
    height: 100%;
    background:#f1f1f1;
    display:block;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 0 2px 8px -3px #777;
    background-image: url(../images/right-arrow.svg);
    background-repeat: no-repeat;
    background-position: 96% 96%;

    &:hover,
    &:focus {
        transform: scale(1.05);
    }
}
.resource-listing__image {
    height:210px;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 4px 4px 0 0;
}
.resource-listing__content{
    padding:15px 20px; 
}
.resource-listing__meta-heading {
    @include x-rem(font-size, 14px);
    font-weight: bold;
    color:#333;
    margin:0 0 2px;
    font-family: $font-primary;
}
.resource-listing__heading {
    @include x-rem(font-size, 19px);
    font-weight: bold;
    color:$color-primary;
    margin:0;
    font-family: $font-primary;
    margin:0 0 10px;
    display:flex;

    &.has-arrow:after {
        flex:0 0 8px;
    }
}
.resource-listing__heading-icon {
    width:20px;
    height:20px;
    fill:currentColor;
    vertical-align: middle;
}

@media (min-width: $bp-sw) {
    .resource-listing {
        display:flex;
        flex-wrap: wrap;
        margin:0 -15px;
    }
    .resource-listing__item {
        width:50%;
        padding:0 15px;
    }
}

@media (min-width: $bp-m) {
    
    //per row property
    .resource-listing--3 {
        .resource-listing__item {
            width:33.33%;
        }
    }
    .resource-listing--2 {
        .resource-listing__item {
            width:50%;
        }
    }
    .resource-listing--1 {
        .resource-listing__item {
            width:100%;
        }
    }
}
@media (min-width: $bp-mw) {
    .resource-listing__item {
        width:25%;
    }

    //per row property
    .resource-listing--3 {
        .resource-listing__item {
            width:33.33%;
        }
    }
    .resource-listing--2 {
        .resource-listing__item {
            width:50%;
        }
    }
    .resource-listing--1 {
        .resource-listing__item {
            width:100%;
        }
    }
}