.site-footer {
    border-top:4px solid $color-secondary-lightest;
    box-shadow: inset 0 3px 3px 3px #111;
    content-visibility:auto;
}

.footer-top {
    background: $color-primary;
}

.footer-heading {
    color:$color-invert;
    font-family:$font-tertiary;
    @include x-rem(font-size, 18px);
    padding:10px 10px 10px 20px;
    margin: 0 -15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid #8090A3;
}
.footer-links-expander {
    border:0;
    padding:0;
    background:none;
    width:30px;
    height:30px;
    position: relative;
    cursor: pointer;

    &:before {
        content:'';
        width:14px;
        height:2px;
        background:$color-invert;
        position: absolute;
        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%);
    }
    &:after {
        content:'';
        width:2px;
        height:14px;
        background:$color-invert;
        position: absolute;
        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.footer-links {
    @include resetList;
    border-bottom:1px solid #8090A3;
    padding:0 20px;
}
.footer-links__item {
    margin:10px 0;
}
.footer-links__link {
    @include x-rem(font-size, 18px);
    text-decoration: none;
    color:$color-invert;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.footer-top-logo-social {
    text-align: center;
    padding:25px 0;
}
.footer-logo {
    display:block;  
    margin:0 0 25px;
}
.footer-social-list {
    @include resetList;
    display:flex;
    justify-content: center;
}
.footer-social-list__item {
    margin:0 10px;
}
.footer-social-list__link {
    color:$color-invert;
    display:block;

    &:hover,
    &:focus {
        color:$color-secondary-light;
    }
}
.footer-social-list__icon {
    width:24px;
    height:24px;
    fill:currentColor
}

.footer-bottom {
    background:#fff;
    padding:20px 0;
}

.footer-bottom-top {
    margin:0 0 30px;
}
.footer-bottom-text {
    @include x-rem(font-size, 14px);
    margin:0 0 15px;
    line-height:1.6;
}
.footer-bottom-links {
    @include resetList;
}
.footer-bottom-links__item {
    margin:0 0 10px;
}
.footer-bottom-links__link {
    @include x-rem(font-size, 14px);
}
.footer-logos {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin:0 0 15px;

    img {
        margin:0 5px;
    }
}
.footer-copyright {
    text-align: left;
    @include x-rem(font-size, 14px);
}
.footer-newsletters-box{
order: 3;
}
.footer-newsletters{
    @include resetList;
    display: flex;
    flex-direction: column;
    @include x-rem(gap, 10px );


}
.footer-newsletters__link{

}
.footer-newsletters__link{
    text-decoration: none;
    color: $color-primary; 
    @include x-rem(font-size, 14px);
    &:hover, &:focus{
        text-decoration: underline;

    }
    
}
.footer-disclaimer {
    border:1px solid #ccc;
    padding:22px 25px;
}
.footer-disclaimer__heading {
    @include x-rem(font-size, 14px);
    font-weight:500;
    margin:0 0 10px;
    font-family:$font-primary;
}
.footer-disclaimer-list {
    @include resetList;
}
.footer-disclaimer-list__item {
    @include x-rem(font-size, 14px);
    display:flex;
    align-items: center;
    text-transform: uppercase;
    margin:0 0 5px;

    &:last-child:after {
        display:none;
    }
}

@media (min-width: $bp-m) {

    .site-footer {
    
    }
    
    .footer-top {
        padding:50px 0;
    }
    
    .footer-heading {
        margin:0 0 10px;
        @include x-rem(font-size, 20px);
        padding:0;
        border:0;
    }
    .footer-links-expander {
        display:none;
    }
    
    .footer-links {
        display:block !important;
        padding:0;
        border:0;
    }
    .footer-links__item {
        margin:0 0 12px;
    }
    
    .footer-top-logo-social {
        text-align: left;
        padding:20px 0 0;
    }
    .footer-social-list {
        justify-content: flex-start;
    }
    .footer-bottom {
        padding:35px 0;
    }
    .footer-bottom-top {
        display:flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .footer-bottom-text {
        width:calc(100% - 200px);
        order:1;
        margin:0 0 30px;
    }
    .footer-bottom-links {
        order:3;
        display:flex;
        flex-wrap: wrap;
        width:calc(100% - 320px);
    }
    .footer-bottom-links__item {
        margin:0 25px 0 0;
    }
    .footer-logos {
        order:2;
        width:200px;
        text-align: right;
        justify-content: flex-end;
    
        img {
            margin:0 0 0 5px;
        }
    }
    .footer-copyright {
        order:4;
        margin-left:auto;
        width:320px;
    }
    .footer-newsletters{
        flex-direction: row;
        @include x-rem(gap, 30px);
    }
    .footer-disclaimer-list {
        display:flex;
        flex-wrap: wrap;
    }
    .footer-disclaimer-list__item {

        &:after {
            content:'';
            width:4px;
            height:4px;
            background:$color-base;
            border-radius:50%;
            margin:0 5px;
            display:inline-block;
            vertical-align: middle;
        }
    }
}
@media (min-width: $bp-mw) {
    .footer-top-logo-social {
        text-align: right;
        padding:0;
    } 
}
