.biography__title { 
    color: $color-primary;
}

.biography__position {
    color: $color-primary;
}

.biography__social-media {
    display: flex;
    margin:0 -8px;

    > p {
        padding:0 7px;
    }
}

.biography__icon { 
    width: 24px;
    height: 24px;
    fill: $color-primary;
}

.biography__icon:hover {
    transition: all .18s ease-in-out;
    fill: $color-secondary-light;
}

.biography__bio p {
    font-size: 18px;
    font-family: $font-tertiary;
    line-height: 1.4;
    color: $color-base;
}

.biography__nmls {
    font-size: 18px;
    font-family: $font-tertiary;
    line-height: 1.4;
    color: $color-primary;
    font-weight: 900;
}

.biolists__card {
    padding:25px 0;
    border-bottom: 1px solid $color-gray;
}

.bio-lists__right a {
    
    &.has-arrow {
        display:inline-flex;
        align-items: center;
    }
}

.bio-lists__left {
}

.bio-lists__right {

}


@media (min-width:$bp-m){
    .bio-lists__left {
        display: block;
    }
    .bio-lists__right {
        width: 66.66%;
        margin: none;
    }
    .biolists__description {
        @include x-rem(font-size, 20px);
    }
    .bio-lists__right a {
    
        &.has-arrow {
            @include x-rem(font-size, 18px);
        }
    }
}

// Details
.biography__image {
    margin:0 0 20px;
}