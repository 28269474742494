.card-listing {
    @include resetList;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #333F48;
    margin: 0 -10px 30px;
}

.card-item {
    width: 50%;
    @include x-rem(font-size, 14px);
    padding: 0 10px;
    margin: 0 0 20px;
    line-height: 1.4;

    // Last 2 items
    &:nth-last-child(-n+2) {
        margin: 0;
    }
}

.card-item__inner {
    display: block;

    @at-root a#{&} {
        color: currentColor;

        &:hover,
        &:focus {
            color: $color-primary;
            text-decoration: none;
        }
    }
}

.card-item__img {
    @include lazy-fade-in();
    background: no-repeat center / cover;
    position: relative;
    margin: 0 0 15px;

    &::after {
        content: '';
        display: block;
        padding-top: percentage(105/180);
    }
}

.card-item__title {
    font-weight: $fontWeight-semibold;
    background-image: linear-gradient(transparent calc(100% - 1px), currentColor 1px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: $trans;
    margin: 0;
}

.card-item__meta {
    display: block;
    color: #737373;
    margin: 3px 0 0;
}

@media (min-width: $bp-mw) {
    .card-listing {
        flex-wrap: none;
        margin: 0 0 70px;
    }

    .card-item {
        flex: 1 0 0px;
        width: auto;
        margin: 0 40px 0 0;
        padding: 0;

        // overwrite mobile style
        &:nth-last-child(-n+2) {
            margin: 0 40px 0 0;
        }
    }

    .card-item__title {
        @include x-rem(font-size, 16px);
    }
}

// featured newsletter cards

.featured-newsletter {
    @include resetList;
    display: flex;
    flex-direction: column;
    @include x-rem(gap, 28px);
    @include breakpoint($bp-m) {
      @include x-rem(gap, 40px);
      flex-direction: row;
    }
  }
  .featured-newsletter__item {
    position: relative;
    @include breakpoint($bp-m) {
      width: 50%;
      display: flex;
      align-items: flex-end;
      @include x-rem(padding-top, 275px);
    }
  }
  .featured-newsletter__imgbox {
    @include bgImg;
    width: 100%;
    @include x-rem(height, 280px);
    @include breakpoint($bp-m) {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  
  .featured-newsletter__content {
    position: relative;
    background: $body-bg;
    @include x-rem(padding, 30px 15px 24px 15px);
    @include breakpoint($bp-m) {
      width: 100%;
      @include x-rem(max-width, 460px);
      margin-left: auto;
      @include x-rem(gap, 40px);
      @include x-rem(padding, 34px 38px 28px 38px);
    }
  }
  .featured-newsletter__title {
    text-align: center;
    @include x-rem(margin-bottom, 10px);
    letter-spacing: -0.6px;
    @include breakpoint($bp-m) {
      text-align: initial;
      @include x-rem(margin-bottom, 4px);
      letter-spacing: -0.8px;
    }
  }
  .featured-newsletter__desc {
    font-size: 1rem;
    @include x-rem(line-height, 24px);
    color: $color-gray3;
    @include x-rem(margin-bottom, 20px);
    @include breakpoint($bp-m) {
      @include x-rem(font-size, 20px);
      @include x-rem(line-height, 30px);
      @include x-rem(margin-bottom, 30px);
    }
  }